import { Dispatch, FC, SetStateAction } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";
import {
  LocalizationProvider,
  LocalizationProviderProps,
  MobileDatePicker,
} from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

interface Props {
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  locale: LocalizationProviderProps | any;
  handleChange?: () => void;
  showDateModal: boolean;
  setShowDateModal: Dispatch<SetStateAction<boolean>>;
  disablePast?: boolean;
}

const datePickertheme = createTheme({
  typography: {
    fontFamily: "Raleway",
    fontSize: 18,
  },
});

const CustomDatePicker: FC<Props> = (props) => {
  const {
    locale,
    selectedDate,
    setSelectedDate,
    handleChange,
    setShowDateModal,
    showDateModal,
    disablePast,
  } = props;
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={locale}>
      <ThemeProvider theme={datePickertheme}>
        <MobileDatePicker
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(new Date(e!));
            handleChange && handleChange();
          }}
          open={showDateModal}
          renderInput={() => <></>}
          showToolbar={false}
          disablePast={disablePast}
          onClose={() => {
            setShowDateModal(!showDateModal);
          }}
          views={["month", "year", "date"]}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
