import React from "react";
import useSpecificChannel from "./useSpecificChannel";
import * as yup from "yup";

/**
 * @todo kayaknya schema ini bisa masuk ke schema `useSchemaUpdateWIReservation` sama `useSchemaUpdateReservation`
 *
 * @param channelId channel id
 * @param pickedTicketPhoto array of photos
 * @returns yup Schema
 */
const useSchemaManagePhotoReservation = (
  channelId: number,
  pickedTicketPhoto: any[]
) => {
  const { channel, isLoading } = useSpecificChannel(channelId);
  return React.useMemo(() => {
    let { statusPhoto } = channel ?? {};
    let images = yup.array().nullable();
    let schema = yup.object().shape({
      images:
        statusPhoto && statusPhoto.active
          ? statusPhoto.optional
            ? images.optional()
            : pickedTicketPhoto.length === 0
            ? images
                .test("len", "Wajib mengisi foto", (val) =>
                  val ? val.length > 0 : false
                )
                .required("Wajib mengisi foto")
            : images.required("Wajib mengisi foto")
          : images.required("Wajib mengisi foto"),
    });
    return {
      schema,
      isLoading,
    };
  }, [channel, isLoading]);
};

export default useSchemaManagePhotoReservation;
