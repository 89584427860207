import React, { ChangeEvent, MutableRefObject, useRef } from "react";
import { toast } from "react-toastify";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppCookies } from "../hooks/auth";
import useUsertype from "../hooks/auth/useUsertype";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import Header from "../components/atoms/Header";
import { ReservationPageState } from "../types/reservation/reservationPageState";
import { format } from "date-fns";
import { normalDateFormat, shortDateFormat } from "../types/dateformat";
import id from "date-fns/locale/id";
import { useGetChannelsQuery } from "../redux/ChannelStore/channelstoreAPI";
import { useGetVendorsQuery } from "../redux/VendorStore/vendorstoreAPI";
import { AVendorOpt } from "../types/vendor";
import PureInputSelect from "../components/atoms/PureInputSelect";
import { useUser } from "../hooks/auth/useUser";
import useMaterialOptions from "../hooks/materials/useMaterialOptions";
import { AMaterialOpt } from "../types/material";
import * as yup from "yup";
import {
  useAddPhotoReservationMutation,
  useCreateAReservationMutation,
} from "../redux/ReservationStore/reservationstoreAPI";
import { ROUTES } from "../config/nav";
import { useDefaultRoute } from "../components/HOC/Redirector";
import useSchemaCreateReservation from "../hooks/channels/useSchemaCreateReservation";
import { ErrorMessageBackendDataShape } from "../types";
import useButtonStyles from "../hooks/styles/useButtonStyles";
import ImageViewer from "../components/atoms/FileInput/ImageViewer";
import { BiPlus } from "react-icons/bi";
import { ButtonBase, makeStyles } from "@material-ui/core";
import {
  replaceInputTextContainer,
  replaceInputTextLicensePlate,
  replaceInputTextToNumberOnly,
} from "../tools";

interface Props {}

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    headerContainer: {
      flexDirection: "row",
      display: "flex",
      marginBottom: "5vh",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    reservationDetailContainer: {
      margin: "16px 0",
      padding: "0 8px",
    },
    reservationFormContainer: {
      minHeight: "100vh",
      padding: "16px 0",
    },
    submitButtonContainer: {
      width: "100%",
      fontWeight: 600,
      fontSize: "1.5rem",
      padding: "1.6rem 0",
      letterSpacing: 0.65,
      color: "white",
      borderRadius: ".8rem",
      border: `1px solid`,
      background: COLORS.gradientBlueGreen_1,
      marginBottom: "1rem",
      "&:hover": {
        opacity: 0.7,
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        opacity: 0.4,
      },
    },
    textTitleHeader: {
      color: "black",
      fontFamily: "Raleway",
      fontWeight: 600,
      fontSize: "15px",
    },
    textReservationDetail: {
      color: "black",
      fontFamily: "Raleway",
      fontSize: 13,
    },
    textFormTitle: {
      fontFamily: "Raleway",
      fontSize: 12,
      position: "relative",
      fontWeight: 600,
    },
    formInput: {
      padding: "1rem 1.6rem",
      borderRadius: "4px",
      border: "1px solid #DDDDDD",
    },
    mandatoryStar: {
      color: "red",
      fontSize: 24,
      verticalAlign: "middle",
      height: 0,
      position: "absolute",
      top: -8,
      margin: 0,
    },
  };
});

const ReservationFormPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const btn = useButtonStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state as ReservationPageState;
  const [selectedVendor, setSelectedVendor] = React.useState<AVendorOpt | null>(
    null
  );
  const uploadPhotosRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [selectedTruck, setSelectedTruck] = React.useState<string>("");
  const [selectedDriverName, setSelectedDriverName] =
    React.useState<string>("");
  const [selectedDriverNumber, setselectedDriverNumber] =
    React.useState<string>("");
  const [selectedPO, setSelectedPO] = React.useState<string>("");
  const [selectedPOLine, setSelectedPOLine] = React.useState<string>("");
  const [selectedMaterial, setSelectedMaterial] =
    React.useState<AMaterialOpt | null>(null);

  const [selectedDeliveryNote, setSelectedDeliveryNote] =
    React.useState<string>("");

  const [selectedShipmentNo, setSelectedShipmentNo] =
    React.useState<string>("");
  const [photos, setPhotos] = React.useState<File[]>([]);
  const handleChangePhotos = (event: ChangeEvent<HTMLInputElement>) => {
    let { files } = event.target;
    if (files) {
      let fileArr: File[] = [];
      for (let i = 0; i < files.length; i++) {
        const el = files[i];
        if (el.size < 1024000) {
          fileArr.push(el);
        } else {
          toast.error("Gagal Unggah Gambar! Ukuran file lebih dari 1024 kb.");
        }
      }
      setPhotos((prev) => {
        let newarr = prev.concat(fileArr);
        return newarr;
      });
    }
  };

  const [selectedContainer, setSelectedContainer] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const usertype = useUsertype();
  const defaultRoute = useDefaultRoute();
  const [{ channelId }] = useAppCookies(["channelId"]);
  const {
    data: [channel] = [],
    isFetching: isFetchingChannel,
    isLoading: isLoadingChannel,
  } = useGetChannelsQuery({ id: parseInt(channelId) });
  const [createReservation] = useCreateAReservationMutation();
  const [addPhotoReservation] = useAddPhotoReservationMutation();
  const { schema: validationSchema } = useSchemaCreateReservation(
    parseInt(channelId)
  );
  const { user: { vendor } = {}, isLoading: isLoadingUser } = useUser();
  const {
    data: vendors,
    isFetching: isFetchingVendors,
    isLoading: isLoadingVendors,
  } = useGetVendorsQuery(
    {},
    { skip: !(usertype === "officer" || usertype === "superadmin") }
  );
  const { arr: materialOpts, isLoading: isLoadingMaterials } =
    useMaterialOptions({
      args: {
        channel: channel?.channel,
      },
    });

  const vendorOpts = React.useMemo<AVendorOpt[]>(() => {
    if (!vendors || vendors.length < 1) {
      return [];
    }
    return vendors.map((val) => {
      return {
        ...val,
        label: val.name,
        value: val.vendcode,
      };
    });
  }, [vendors]);

  const defaultVendor = React.useMemo<AVendorOpt | null>(() => {
    if (usertype === "user") {
      if (vendor) {
        return {
          ...vendor,
          label: vendor.name,
          value: vendor.vendcode,
        };
      }
    }
    return null;
  }, [usertype, vendor]);

  const onSubmitReservation: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (e) => {
        e.preventDefault();
        const fields = validationSchema.fields;
        const { statusPhoto } = channel || {};
        // whether the photo should be uploaded
        // after validation via yup schema
        const shouldUploadPhotos = statusPhoto?.active
          ? !statusPhoto.optional
            ? true
            : !!photos.length
          : false;

        let data: {
          [key in keyof typeof fields]?: any;
        } = {
          channelId: channelId,
          scheduleId: params.quota.id,
          date: format(params.date, shortDateFormat),
          nopol: selectedTruck,
        };
        !!channel?.statusVendor.active &&
          Object.assign(data, {
            vendcode:
              usertype === "user"
                ? defaultVendor?.vendcode
                : selectedVendor?.vendcode,
          });
        !!channel?.statusDriverName.active &&
          Object.assign(data, { drivername: selectedDriverName });
        !!channel?.statusDriverPhoneNumber?.active &&
          Object.assign(data, { driverPhoneNumber: selectedDriverNumber });
        !!channel?.statusMaterial.active &&
          Object.assign(data, { materialId: selectedMaterial?.value });
        !!channel?.statusDeliveryNote.active &&
          Object.assign(data, { deliveryNote: selectedDeliveryNote });
        !!channel?.statusPo.active &&
          Object.assign(data, {
            ponum: selectedPO,
            poline: parseInt(selectedPOLine),
          });
        !!channel?.statusContainer.active &&
          Object.assign(data, {
            container: selectedContainer,
          });
        !!channel?.statusShipmentNumber.active &&
          Object.assign(data, {
            shipmentNumber: selectedShipmentNo,
          });

        !!channel?.statusPhoto.active &&
          Object.assign(data, {
            images: photos,
          }); // for validation only.
        validationSchema
          .validate(data)
          .then((val) => {
            setIsSubmitting(true);
            createReservation(val)
              .unwrap()
              .then(async ({ raw }) => {
                delete val.images; // images will be uploaded in different endpoint
                let [{ id }] = raw;

                if (shouldUploadPhotos) {
                  const formData = new FormData();
                  for (let i = 0; i < photos.length; i++) {
                    formData.append("images[]", photos![i]);
                  }
                  await addPhotoReservation({
                    id: id,
                    images: formData,
                  })
                    .unwrap()
                    .then(() => {
                      if (photos.length > 0) {
                        toast.success("Berhasil menambahkan gambar.");
                      }
                    })
                    .catch(() => {
                      toast.error(`Gagal menambahkan gambar`);
                    });
                }
                let route = `${ROUTES.ticket}/${id}`;
                navigate(defaultRoute, { replace: true });
                navigate(route);
                toast.success("Berhasil menambahkan reservasi");
              })
              .catch((e: ErrorMessageBackendDataShape) => {
                toast.error(`Gagal membuat reservasi ${e.data.message}`);
              })
              .finally(() => setIsSubmitting(false));
          })
          .catch((e: yup.ValidationError) => {
            toast.error(`Gagal membuat reservasi! ${e.errors.join(", ")}`);
          });
      },
      [
        usertype,
        channelId,
        defaultRoute,
        selectedTruck,
        selectedMaterial,
        selectedDriverName,
        navigate,
        params,
        channel,
        selectedVendor,
        defaultVendor,
        selectedPO,
        validationSchema,
        createReservation,
        selectedDeliveryNote,
        selectedDriverNumber,
        selectedPOLine,
      ]
    );

  return (
    <PageContainer
      headerContainerStyle={{
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
      mainContainerStyle={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
      headerNavigationContentStyle={{ paddingBottom: 16 }}
      headerNavigationContent={
        <>
          <Header
            showBackButton={true}
            backButtonOnClick={() => {
              navigate(-1);
            }}
            backButtonColor={COLORS.white}
            headerTextColor={COLORS.white}
            title={"Reservasi"}
          />
        </>
      }
      headerBackground={COLORS.gradientBlueGreen_1}
      headerContent={
        <div className={classes.reservationDetailContainer}>
          <Row>
            <Col lg="2" xs="3">
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                Tanggal
              </p>
            </Col>
            <Col>
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                :{" "}
                {format(new Date(params.date!), normalDateFormat, {
                  locale: id,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="2" xs="3">
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                Pukul
              </p>
            </Col>
            <Col>
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                : {params.quota.from} - {params.quota.to}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="2" xs="3">
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                Channel
              </p>
            </Col>
            <Col>
              <p
                className={classes.textReservationDetail}
                style={{ color: COLORS.white, fontWeight: 600 }}
              >
                : {channel ? channel.description : "-"}
              </p>
            </Col>
          </Row>
        </div>
      }
      className={classes.container}
      withBackground
    >
      <div className={classes.reservationFormContainer}>
        <Form id="reservationForm" onSubmit={onSubmitReservation}>
          <Row>
            <Col lg="2" xs="3">
              <div style={{ marginBottom: 6, paddingBottom: 16 }}>
                <span
                  style={{
                    color: COLORS.red_1,
                    fontWeight: 600,
                    position: "relative",
                    left: 12,
                  }}
                >
                  <span
                    style={{
                      fontSize: 24,
                      position: "absolute",
                      top: -6,
                      left: -12,
                    }}
                  >
                    *
                  </span>{" "}
                  Wajib Diisi
                </span>
              </div>
            </Col>
          </Row>
          {(!!channel?.statusVendor.active || usertype === "user") && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Vendor{" "}
                {!channel?.statusVendor.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <PureInputSelect
                isDisabled={usertype === "user" || isSubmitting}
                isLoading={
                  isLoadingVendors || isFetchingVendors || isLoadingUser
                }
                defaultValue={defaultVendor}
                options={vendorOpts}
                placeholder="Pilih Vendor"
                isSearchable
                isClearable
                isLargeDataList
                value={usertype === "user" ? defaultVendor : selectedVendor}
                onChange={(val) => {
                  setSelectedVendor(val);
                }}
              />
            </div>
          )}
          <div style={{ marginBottom: 16 }}>
            <Label className={classes.textFormTitle}>
              Nomor Kendaraan <span className={classes.mandatoryStar}>*</span>
            </Label>
            <Input
              id="nopol"
              className={classes.formInput}
              disabled={isSubmitting}
              name="nopol"
              placeholder="No. Kendaraan"
              value={selectedTruck}
              required
              onChange={(e) => {
                setSelectedTruck(e.target.value);
              }}
              onInput={replaceInputTextLicensePlate}
            />
          </div>
          {!!channel?.statusDriverName.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Pengemudi{" "}
                {!channel?.statusDriverName.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="driver"
                className={classes.formInput}
                disabled={isSubmitting}
                name="pengemudi"
                placeholder="Pengemudi"
                required={!channel?.statusDriverName.optional}
                value={selectedDriverName}
                onChange={(e) => {
                  setSelectedDriverName(e.target.value);
                }}
              />
            </div>
          )}
          {!!channel?.statusDriverPhoneNumber?.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Nomor Pengemudi{" "}
                {!channel?.statusDriverPhoneNumber.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="driver_no"
                className={classes.formInput}
                disabled={isSubmitting}
                name="Nomor Pengemudi"
                placeholder="Nomor Pengemudi"
                required={!channel?.statusDriverPhoneNumber.optional}
                value={selectedDriverNumber}
                onInput={replaceInputTextToNumberOnly}
                onChange={(e) => {
                  setselectedDriverNumber(e.target.value);
                }}
              />
            </div>
          )}
          {!!channel?.statusMaterial.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Jenis Barang{" "}
                {!channel?.statusMaterial.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <PureInputSelect
                options={materialOpts}
                value={selectedMaterial}
                onChange={(val) => {
                  setSelectedMaterial(val);
                }}
                isDisabled={isSubmitting}
                isLoading={isLoadingMaterials}
                placeholder="Pilih Jenis Barang"
              />
            </div>
          )}
          {!!channel?.statusPo.active && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                width: "100%",
              }}
            >
              <div style={{ marginBottom: 16, flex: 1 }}>
                <Label className={classes.textFormTitle}>
                  Nomor PO{" "}
                  {!channel?.statusPo.optional && (
                    <span className={classes.mandatoryStar}>*</span>
                  )}
                </Label>
                <Input
                  id="inputPo"
                  className={classes.formInput}
                  disabled={isSubmitting}
                  name="inputPo"
                  placeholder="Nomor PO"
                  value={selectedPO}
                  type="number"
                  onChange={(e) => {
                    setSelectedPO(e.target.value);
                  }}
                  required={!channel?.statusPo.optional}
                />
              </div>
              <div style={{ marginBottom: 16, flex: "0 0 50%" }}>
                <Label className={classes.textFormTitle}>
                  Line{" "}
                  {!channel?.statusPo.optional && (
                    <span className={classes.mandatoryStar}>*</span>
                  )}
                </Label>
                <Input
                  id="inputPoLine"
                  className={classes.formInput}
                  disabled={isSubmitting}
                  name="inputPoLine"
                  placeholder="Nomor PO Line"
                  value={selectedPOLine}
                  type="number"
                  required={!channel?.statusPo.optional}
                  onChange={(e) => {
                    setSelectedPOLine(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          {channel?.statusDeliveryNote.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Delivery Note{" "}
                {!channel?.statusDeliveryNote.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="delivery_note"
                className={classes.formInput}
                name="delivery_note"
                disabled={isSubmitting}
                placeholder="Delivery Note"
                value={selectedDeliveryNote}
                required={!channel?.statusDeliveryNote.optional}
                onChange={(e) => {
                  setSelectedDeliveryNote(e.target.value);
                }}
              />
            </div>
          )}
          {channel?.statusShipmentNumber.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                Shipment No.{" "}
                {!channel?.statusShipmentNumber.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="shipment_no"
                className={classes.formInput}
                name="shipment_no"
                disabled={isSubmitting}
                placeholder="Shipment No."
                value={selectedShipmentNo}
                required={!channel?.statusShipmentNumber.optional}
                onChange={(e) => {
                  setSelectedShipmentNo(e.target.value);
                }}
              />
            </div>
          )}
          {channel?.statusContainer.active && (
            <div style={{ marginBottom: 16 }}>
              <Label className={classes.textFormTitle}>
                No Container{" "}
                {!channel?.statusContainer.optional && (
                  <span className={classes.mandatoryStar}>*</span>
                )}
              </Label>
              <Input
                id="no_container"
                className={classes.formInput}
                name="no_container"
                disabled={isSubmitting}
                placeholder="No Container"
                value={selectedContainer}
                required={!channel?.statusContainer.optional}
                onChange={(e) => {
                  setSelectedContainer(e.target.value);
                }}
                onInput={replaceInputTextContainer}
              />
            </div>
          )}
          {channel?.statusPhoto.active && (
            <div style={{ marginBottom: 16 }}>
              <FormGroup>
                <p
                  className={classes.textFormTitle}
                  style={{ marginBottom: 6 }}
                >
                  Foto{" "}
                  {!channel?.statusPhoto.optional && (
                    <span className={classes.mandatoryStar}>*</span>
                  )}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    alignItems: "center",
                  }}
                >
                  <ButtonBase
                    className={btn.uploadPhotosButton}
                    onClick={() => uploadPhotosRef.current.click()}
                    type="button"
                    style={{ width: "100%", fontWeight: 600 }}
                  >
                    <BiPlus
                      size={14}
                      style={{ marginBottom: 2, marginRight: 4 }}
                    />
                    <span style={{ fontWeight: 600, fontSize: 13 }}>
                      Unggah Gambar
                    </span>
                  </ButtonBase>
                  <input
                    id="image"
                    type="file"
                    name="image"
                    ref={uploadPhotosRef}
                    accept="image/*"
                    multiple
                    hidden
                    disabled={isSubmitting}
                    onChange={handleChangePhotos}
                  />
                  <span
                    style={{
                      fontSize: 10,
                      marginBottom: 0,
                      fontStyle: "italic",
                    }}
                  >
                    Keterangan: Format (.jpg /.png), max 1024 kb
                  </span>
                </div>
              </FormGroup>
              {!!photos.length && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                    overflowX: "auto",
                  }}
                >
                  {photos.map((val, idx) => {
                    return (
                      <ImageViewer
                        key={idx}
                        file={val}
                        containerStyle={{
                          height: 100,
                          width: 100,
                        }}
                        withDeleteIcon
                        onDelete={() => {
                          let newList = photos.slice();
                          newList.splice(idx, 1);
                          setPhotos(newList);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </Form>
        <ButtonBase
          form="reservationForm"
          type="submit"
          style={{ marginTop: 16 }}
          className={classes.submitButtonContainer}
          disabled={
            !channel || isLoadingChannel || isFetchingChannel || isSubmitting
          }
        >
          BUAT RESERVASI
        </ButtonBase>
      </div>
    </PageContainer>
  );
};

export default ReservationFormPage;
