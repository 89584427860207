export const ROUTES = {
  home: "/",
  loginpage: "/login",
  superadmin: "/superadmin",
  forgotPassword: "/forgotpassword",
  changePassword: "/changePassword",
  user: "/vendor",
  officer: "/officer",
  approver: "/approver",
  ticket: "/reservation",
  walkinTicket: "/reservation/walkin",
} as const;

export const USER_ROUTES = {
  reservationList: `${ROUTES.user}/listreservation`,
  reservationDetails: `${ROUTES.user}/detailreservation`,
} as const;

export const OFFICER_ROUTES = {
  reservationList: `${ROUTES.officer}/listreservation`,
  walkInReservationList: `${ROUTES.officer}/listwalkin`,
} as const;
