import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUserType } from "../../types/user";

export interface userTypesState {
  userTypesData: AUserType[];
}

const initialState: userTypesState = {
  userTypesData: [],
};

export const userTypesSlice = createSlice({
  name: "USER_TYPES_SLICE",
  initialState,
  reducers: {
    storeUserTypesData: (state, payload: PayloadAction<AUserType[]>) => {
      state.userTypesData = payload.payload;
    },
  },
});

export default userTypesSlice.reducer;
export const { storeUserTypesData } = userTypesSlice.actions;
