import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { FajarPaperSVG } from "../../assets/Icons";
import PageContainer from "../../components/PageContainer/PageContainer";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import useLoginStyles from "./useLoginStyles";
import { useLogin } from "../../hooks/auth";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDefaultRoute } from "../../components/HOC/Redirector";
import Truck from "../../components/atoms/Truck";
import { storeUserData } from "../../redux/UserStore/userstore";
import { useAppDispatch } from "../../redux";
import { getUser } from "../../api/user/getUser";
import { COLORS } from "../../assets/theme";

interface Props {}

const LoginPage: React.FunctionComponent<Props> = () => {
  const [showPwd, setShowPwd] = React.useState(false);
  const [usr, setUsr] = React.useState<string>("");
  const [pwd, setPwd] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useLoginStyles();
  const login = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const usrInputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (usrInputRef.current) {
      usrInputRef.current.focus();
    }
  }, []);
  let from = React.useMemo(() => {
    let state = location.state;
    if (typeof state === "object") {
      if (state) {
        const { from } = state as { from?: string };
        return from;
      }
    }
  }, [location]);

  const onKeyEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key.toLowerCase() === "enter") {
      const inputEl = e.target as HTMLInputElement;
      const form = inputEl.form;
      if (form) {
        // @ts-ignore
        const idx = [...form].indexOf(inputEl);
        const nextInput = form.elements[idx + 1] as
          | HTMLInputElement
          | undefined;
        if (nextInput) {
          nextInput.focus();
        }
      }
      e.preventDefault();
    }
  };

  const onLogin: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);
      login({ username: usr, password: pwd })
        .then(async ({ usertypeName, ...rest }) => {
          getUser({ id: rest.id }).then(({ ...restUser }) => {
            let dataUser = restUser.data.data.entities[0];
            dispatch(storeUserData({ ...dataUser, usertypeName, ...rest }));
            toast("Login Berhasil", {
              type: "success",
            });
            const defaultRoute = getDefaultRoute(usertypeName);
            navigate(from ?? defaultRoute, { replace: true });
          });
        })
        .catch(() => {
          toast("Gagal login. Silahkan periksa kembali kredensial anda.", {
            type: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [usr, pwd, login, navigate, from, dispatch]
  );

  return (
    <PageContainer className={classes.container}>
      <Form
        className={classes.formContainer}
        id="login_form"
        onSubmit={onLogin}
      >
        <FajarPaperSVG
          viewBox="0 0 320 160"
          style={{
            alignSelf: "center",
            marginRight: 24,
          }}
          className="mb-5"
          width={280}
          height={70}
        />
        <FormGroup className="mb-5">
          <Label for="username" className={classes.labelInput}>
            Username
          </Label>
          <Input
            className={classes.input}
            innerRef={usrInputRef}
            name="username"
            placeholder="Login"
            type="text"
            value={usr}
            onChange={(e) => {
              setUsr(e.target.value);
            }}
            onInput={(e) =>
              (e.currentTarget.value =
                "" + e.currentTarget.value.toLocaleLowerCase())
            }
            onKeyDown={onKeyEnter}
          />
        </FormGroup>
        <FormGroup className="mb-5">
          <Label for="password" className={classes.labelInput}>
            Password
          </Label>
          <div className={classes.inputContainer}>
            <Input
              className={classes.input}
              name="password"
              placeholder="Password"
              value={pwd}
              type={showPwd ? "text" : "password"}
              onChange={(e) => {
                setPwd(e.target.value);
              }}
            />
            <div
              className={classes.passwordEye}
              onClick={(e) => {
                e.stopPropagation();
                setShowPwd((val) => !val);
              }}
            >
              {showPwd ? (
                <AiOutlineEye size={20} />
              ) : (
                <AiOutlineEyeInvisible size={20} />
              )}
            </div>
          </div>
          <Link
            to={"/forgotpassword/sendemail"}
            style={{ textDecoration: "none" }}
          >
            <p
              style={{
                textAlign: "right",
                fontWeight: 700,
                color: COLORS.blue_1,
                marginTop: 16,
                fontSize: 12,
                marginBottom: 0,
                cursor: "pointer",
              }}
            >
              Lupa Password ?
            </p>
          </Link>
        </FormGroup>

        <button
          type="submit"
          disabled={isLoading}
          form="login_form"
          className={classes.loginBtn}
        >
          {isLoading ? "LOADING..." : "LOGIN"}
        </button>
      </Form>
      <Truck className={classes.truck} />
    </PageContainer>
  );
};

export default LoginPage;
