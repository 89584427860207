import React from "react";
import { useAppCookies } from ".";
import { useGetUserListQuery } from "../../redux/UserStore/userstoreAPI";
import { AUserData } from "../../types/user";

/**
 * use only when the user is logged in
 * @returns
 */
export const useUser = (): {
  user?: AUserData;
  isLoading: boolean;
} => {
  const [{ idUser }] = useAppCookies(["idUser"]);
  const { data, isLoading, isFetching } = useGetUserListQuery({ id: idUser });

  return {
    user: data?.entities[0] ?? undefined,
    isLoading: isLoading || isFetching,
  };
};
