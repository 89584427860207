import { COLORS } from "../../../assets/theme";
import { convertToEncodedURL } from "../../../tools";
import { BackendDataShape } from "../../../types";
import {
  AWarehouse,
  AWarehouseWithColor,
} from "../../../types/channel/warehouse";
import { channelstoreAPI } from "../channelstoreAPI";

export const warehouseStoreAPI = channelstoreAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      getWarehouseAll: builder.query<AWarehouseWithColor[], object>({
        query: (obj) => {
          // let params = "";
          // if (obj) {
          //     params = convertToEncodedURL(obj);
          //     params = params.length ? "?" + params : "";
          // }
          return {
            url: `/warehouse/all`,
            method: "GET",
          };
        },
        providesTags: ["WarehouseList"],
        transformResponse: (res: BackendDataShape<AWarehouseWithColor[]>) => {
          const colors = [
            COLORS.orange_1,
            COLORS.purple,
            COLORS.green,
            COLORS.brown,
            COLORS.green_2,
            COLORS.yellow_1_rgb,
            COLORS.blue_2_rgb,
            COLORS.blue_3_rgb,
            COLORS.green_3,
            COLORS.purple_1_rgb,
            COLORS.green_3_rgb,
          ];
          return res.data.map((val, idx) => {
            return {
              ...val,
              color: colors[idx % colors.length],
            };
          });
        },
      }),
      getWarehouse: builder.query<AWarehouseWithColor[], { channelId: number }>(
        {
          query: ({ channelId }) => {
            // let params = "";
            // if (obj) {
            //     params = convertToEncodedURL(obj);
            //     params = params.length ? "?" + params : "";
            // }
            return {
              url: `/${channelId}/warehouse`,
              method: "GET",
            };
          },
          providesTags: ["WarehouseList"],
          transformResponse: (res: BackendDataShape<AWarehouseWithColor[]>) => {
            const colors = [
              COLORS.orange_1,
              COLORS.purple,
              COLORS.green,
              COLORS.brown,
              COLORS.green_2,
              COLORS.yellow_1_rgb,
              COLORS.blue_2_rgb,
              COLORS.blue_3_rgb,
              COLORS.green_3,
              COLORS.purple_1_rgb,
              COLORS.green_3_rgb,
            ];
            return res.data.map((val, idx) => {
              return {
                ...val,
                color: colors[idx % colors.length],
              };
            });
          },
        }
      ),
      createWarehouse: builder.mutation<
        unknown,
        { channelId: number; data: Array<Partial<AWarehouse>> }
      >({
        query: ({ channelId, data }) => {
          return {
            url: `/${channelId}/warehouse`,
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
      updateWarehouse: builder.mutation<
        unknown,
        { channelId: number; data: Array<Partial<AWarehouse>> }
      >({
        query: ({ channelId, data }) => {
          return {
            url: `/${channelId}/warehouse`,
            body: data,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
      deleteWarehouse: builder.mutation<
        BackendDataShape<string>,
        {
          /**
           * company Id
           */
          channelId: number;
          warehouseId: number;
        }
      >({
        query: ({ channelId, warehouseId }) => {
          return {
            url: `/${channelId}/warehouse/${warehouseId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["WarehouseList"],
      }),
    };
  },
});

export const {
  useGetWarehouseQuery,
  useGetWarehouseAllQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
  util: { resetApiState: resetWarehouseAPI },
} = warehouseStoreAPI;
