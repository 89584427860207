import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegistRes } from "../../types/user";
import {} from "../../types/";

export interface Registration {
  registrationData: RegistRes[];
}

const initialState: Registration = {
  registrationData: [],
};

export const registrationSlice = createSlice({
  name: "REGISTRATION_SLICE",
  initialState,
  reducers: {
    storeRegistrationData: (state, payload: PayloadAction<RegistRes[]>) => {
      state.registrationData = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default registrationSlice.reducer;
export const { storeRegistrationData } = registrationSlice.actions;
