import { makeStyles, Switch } from "@material-ui/core";
import { ChangeEvent } from "react";
import { COLORS } from "../../../assets/theme";

type Props = {
  toggled: boolean;
  defaultValue?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const pxToRem = (px: number, oneRemPx = 17) => `${px / oneRemPx}rem`;

const useStyle = makeStyles((theme) => {
  const borderWidth = 2;
  const width = pxToRem(58);
  const height = pxToRem(36);
  const size = pxToRem(24);
  const gap = (34 - 22) / 2;
  return {
    root: {
      width,
      height,
      padding: 0,
      overflow: "unset",
    },
    switchBase: {
      padding: pxToRem(gap),
      "&$checked": {
        color: "#fff",
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        "& + $track": {
          backgroundColor: COLORS.blue_1,
          opacity: 1,
          border: "none",
        },
        "& $thumb": {
          backgroundColor: "#fff",
        },
      },
    },
    track: {
      borderRadius: 40,
      borderWidth,
      backgroundColor: COLORS.gray,
      opacity: 1,
      transition: theme.transitions.create(["background-color"]),
      boxSizing: "border-box",
    },
    thumb: {
      boxShadow: "none",
      backgroundColor: COLORS.white,
      width: size,
      height: size,
    },
    checked: {},
  };
});

const SwitchButton = (props: Props) => {
  const { toggled, onChange, defaultValue } = props;
  const classes = useStyle();
  return (
    <Switch
      classes={classes}
      checked={toggled}
      defaultChecked={defaultValue}
      onChange={onChange}
    />
  );
};

export default SwitchButton;
