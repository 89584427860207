import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import Reservation from "../assets/Icons/svg/Reservation";
import ReservationList from "../assets/Icons/svg/ReservationList";
import ReservationWalkin from "../assets/Icons/svg/ReservationWalkin";
import WalkInReservation from "../assets/Icons/svg/WalkinReservation";
import { COLORS } from "../assets/theme";
import FajarCarousel from "../components/atoms/FajarCarousel";
import PageContainer from "../components/PageContainer/PageContainer";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/auth";
import useUsertype from "../hooks/auth/useUsertype";
import { OFFICER_ROUTES, ROUTES } from "../config/nav";
import { ButtonBase, makeStyles } from "@material-ui/core";
import SyncMasterDataIconComponent from "../assets/Icons/svg/SyncMasterDataIcon";
import {
  syncContainer,
  syncPo,
  syncTruck,
  syncTruckBlackList,
  syncVendor,
} from "../api/sync";
import {
  AccountManagementIconMenu,
  ChannelManagementIconMenu,
  CreateReservationIconMenu,
  ListReservationIconMenu,
  ScheduleManagementIconMenu,
  SyncMasterDataIconMenu,
  WarehouseManagementIconMenu,
} from "../assets/images";
import { mq } from "../assets/breakpoints";

interface Props {}

export const useHomePageStyles = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  btnContainer: {
    flex: 1,
  },
  carousel: {
    marginTop: "1.6rem",
  },
  btnCard: {
    marginTop: "2.4rem",
    display: "block",
    width: "100%",
    height: 80,
    padding: "1.6rem",
    borderRadius: ".8rem",
    border: 0,
    fontWeight: 600,
    overflow: "hidden",
    position: "relative",
    fontSize: "1.5rem",
    "&:hover": {
      opacity: 0.8,
    },
    "&> .textContainer": {
      display: "flex",
      textAlign: "center",
      gap: "1.6rem",
      alignItems: "center",
      "&> .icon": {
        background: "rgba(255,255,255,0.8)",
        padding: "0.8rem 1rem",
        borderRadius: ".4rem",
      },
      "&> .syncicon": {
        background: "#1F354A",
        padding: "0.8rem 1rem",
        borderRadius: ".4rem",
      },
      "&> .syncicondisabled": {
        backgroundColor: "transparent",
        padding: "0.8rem 1rem",
        borderRadius: ".4rem",
      },
    },
  },
  titleMenuWrapper: {
    marginLeft: 56,
    [mq[0]]: {
      marginLeft: 64,
    },
    [mq[3]]: {
      marginLeft: 72,
    },
  },
  titleMenu: {
    marginBottom: 4,
    fontWeight: 700,
    letterSpacing: 0.5,
    marginLeft: 48,
    textAlign: "left",
    fontSize: "1.35rem",
    [mq[1]]: {
      fontSize: "1.75rem",
    },
    [mq[3]]: {
      marginLeft: 72,
    },
  },
  imageIconMenu: {
    maxWidth: 92,
    position: "absolute",
    left: -4,
    top: -4,
    opacity: "50%",
    transform: "rotate(25deg)",
    [mq[3]]: {
      left: 16,
    },
  },
  reserveBtn: {
    background: COLORS.gradientBlue_3,
    color: "white",
  },
  walkinBtn: {
    background: COLORS.gradientOrange_1,
    color: "white",
  },
  reserveListBtn: {
    background: COLORS.gradientGreen_2,
    color: "white",
  },
  warehouseListBtn: {
    background: COLORS.gradientAsphalt_2,
    color: "white",
  },
  syncMasterDataBtn: {
    background: COLORS.gradientAsphalt_1,
    color: "white",
    "&:disabled": {
      background: COLORS.gradientGray_1,
    },
  },
  walkinreserveBtn: {
    background: COLORS.gradientPurple_1,
    color: "white",
  },
  logoutBtn: {
    marginTop: "5vh",
    fontWeight: 600,
    fontSize: "1.5rem",
    padding: "1.1rem 0",
    color: COLORS.red_1,
    borderRadius: ".8rem",
    border: `1px solid ${COLORS.red_1}`,
    backgroundColor: "white",
    marginBottom: "1rem",
    width: "100%",
    "&:hover": {
      // backgroundColor: "gray",
      opacity: 0.7,
    },
  },
});

const HomePage: React.FunctionComponent<Props> = () => {
  const logout = useLogout();
  const classes = useHomePageStyles();
  const usertype = useUsertype();
  const navigate = useNavigate();
  const [isLoadingSync, setIsLoadingSync] = useState<boolean>(false);

  const handleSyncMasterDataSuperadmin = useCallback(() => {
    setIsLoadingSync(!isLoadingSync);
    let doneFetchedCount = 0;
    Promise.all([
      syncTruck,
      syncTruckBlackList,
      syncPo,
      syncVendor,
      syncContainer,
    ])
      .then((values) => {
        values.forEach((res, index) =>
          res({})
            .then((response) => {
              if (response.data.data === "success") {
                switch (index) {
                  case 0:
                    doneFetchedCount = doneFetchedCount + 1;
                    toast.success("Sinkronisasi Truck Berhasil");
                    break;
                  case 1:
                    doneFetchedCount = doneFetchedCount + 1;
                    toast.success("Sinkronisasi Blacklist Truck Berhasil");
                    break;
                  case 2:
                    doneFetchedCount = doneFetchedCount + 1;
                    toast.success("Sinkronisasi Po Berhasil");
                    break;
                  case 3:
                    doneFetchedCount = doneFetchedCount + 1;
                    toast.success("Sinkronisasi Vendor Berhasil");
                    break;
                  case 4:
                    doneFetchedCount = doneFetchedCount + 1;
                    toast.success("Sinkronisasi Container Berhasil");
                    break;
                  default:
                    toast.success("Sinkronisasi Master Data Berhasil");
                    break;
                }
                if (doneFetchedCount === 5) {
                  setIsLoadingSync(false);
                }
              } else {
                switch (index) {
                  case 0:
                    toast.error("Sinkronisasi Truck Gagal");
                    break;
                  case 1:
                    toast.error("Sinkronisasi Blacklist Truck Gagal");
                    break;
                  case 2:
                    toast.error("Sinkronisasi Po Gagal");
                    break;
                  case 3:
                    toast.error("Sinkronisasi Vendor Gagal");
                    break;
                  case 4:
                    toast.error("Sinkronisasi Container Gagal");
                    break;
                  default:
                    toast.error("Sinkronisasi Master Data Gagal");
                    break;
                }
              }
            })
            .catch((err) => {
              setIsLoadingSync(false);
              toast.error("Gagal Sinkronisasi Master Data");
            })
        );
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingSync(false);
        toast.error("Gagal Sinkronisasi Master Data");
      });
  }, [isLoadingSync]);

  const renderButtonList = () => {
    switch (usertype) {
      case "superadmin":
        return (
          <div className={classes.btnContainer}>
            <ButtonBase
              title="Manajemen Channel"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/channel`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Manajemen Channel</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={ChannelManagementIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            {/* <ButtonBase
              title="Manajemen Channel"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/channel`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <Reservation />
                </div>
                Manajemen Channel
              </div>
            </ButtonBase> */}

            <ButtonBase
              title="Manajemen Jadwal"
              className={[classes.btnCard, classes.walkinBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/schedule`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Manajemen Jadwal</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={ScheduleManagementIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>

            {/* <ButtonBase
              title="Manajemen Jadwal"
              className={[classes.btnCard, classes.walkinBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/schedule`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <WalkInReservation />
                </div>
                Manajemen Jadwal
              </div>
            </ButtonBase> */}

            <ButtonBase
              title="Manajemen Akun"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/account`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Manajemen Akun</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={AccountManagementIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>

            {/* Warehouse Management */}
            {/* <ButtonBase
              title="Manajemen Gudang"
              className={[classes.btnCard, classes.warehouseListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/warehouse`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Manajemen Gudang</h2>
              </div>
              <img
                src={WarehouseManagementIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase> */}

            {/* <ButtonBase
              title="Manajemen Akun"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.superadmin}/account`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <ReservationList />
                </div>
                Manajemen Akun
              </div>
            </ButtonBase> */}
            <ButtonBase
              title="Sync Master Data"
              className={[classes.btnCard, classes.syncMasterDataBtn].join(" ")}
              disabled={isLoadingSync}
              onClick={handleSyncMasterDataSuperadmin}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>
                  {" "}
                  {isLoadingSync ? "Sedang Sinkronisasi..." : "Sinkronisasi"}
                </h2>
                <h2 className={classes.titleMenu}>
                  {" "}
                  {isLoadingSync ? "" : "Master Data"}
                </h2>
              </div>
              <img
                src={SyncMasterDataIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>

            {/* <ButtonBase
              title="Sync Master Data"
              className={[classes.btnCard, classes.syncMasterDataBtn].join(" ")}
              disabled={isLoadingSync}
              onClick={handleSyncMasterDataSuperadmin}
            >
              <div className="textContainer">
                <div
                  className={isLoadingSync ? "syncicondisabled" : "syncicon"}
                >
                  <SyncMasterDataIconComponent />
                </div>
                {isLoadingSync
                  ? "Sedang Sinkronisasi..."
                  : "Sinkronisasi Master Data"}
              </div>
            </ButtonBase> */}
          </div>
        );
      case "officer":
        return (
          <div className={classes.btnContainer}>
            <ButtonBase
              title="Buat Reservasi"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.officer}/pickschedule`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Buat Reservasi</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={CreateReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            <ButtonBase
              title="List Reservasi"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.officer}/listreservation`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>List Reservasi</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={ListReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            {/* <ButtonBase
              title="Buat Reservasi Walk-in"
              className={[classes.btnCard, classes.walkinBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.officer}/onsiteform`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <WalkInReservation />
                </div>
                Buat Walk-in Approval
              </div>
            </ButtonBase> */}
            {/* <ButtonBase
              title="List Reservasi"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.officer}/listreservation`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <ReservationList />
                </div>
                List Reservasi
              </div>
            </ButtonBase> */}
            <ButtonBase
              title="Buat Reservasi Walk-in"
              className={[classes.btnCard, classes.walkinBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.officer}/onsiteform`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Buat Reservasi</h2>
                <h2 className={classes.titleMenu}>Walk in Approval</h2>
              </div>
              <img
                src={CreateReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>

            <ButtonBase
              title="Buat Reservasi Walk-in"
              className={[classes.btnCard, classes.walkinreserveBtn].join(" ")}
              onClick={() => navigate(OFFICER_ROUTES.walkInReservationList)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Daftar Reservasi</h2>
                <h2 className={classes.titleMenu}>Walk in Approval</h2>
              </div>
              <img
                src={ListReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>

            {/* <ButtonBase
              title="List Reservasi Walk-in Approval"
              className={[classes.btnCard, classes.walkinreserveBtn].join(" ")}
              onClick={() => navigate(OFFICER_ROUTES.walkInReservationList)}
            >
              <div className="textContainer">
                <div className="icon">
                  <ReservationWalkin />
                </div>
                List Reservasi Walk-in Approval
              </div>
            </ButtonBase> */}
          </div>
        );
      case "user":
        return (
          <div className={classes.btnContainer}>
            <ButtonBase
              title="Buat Reservasi"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.user}/pickschedule`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>Buat Reservasi</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={CreateReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            {/* <ButtonBase
              title="Buat Reservasi"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.user}/pickschedule`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <Reservation />
                </div>
                Buat reservasi
              </div>
            </ButtonBase> */}
            <ButtonBase
              title="List Reservasi"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.user}/listreservation`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>List Reservasi</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={ListReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            {/* <ButtonBase
              title="List Reservasi"
              className={[classes.btnCard, classes.reserveListBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.user}/listreservation`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <ReservationList />
                </div>
                List Reservasi
              </div>
            </ButtonBase> */}
          </div>
        );
      case "approver":
        return (
          <div className={classes.btnContainer}>
            <ButtonBase
              title="List Reservasi Approval"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.approver}/approval`)}
            >
              <div className={classes.titleMenuWrapper}>
                <h2 className={classes.titleMenu}>List Reservasi Approval</h2>
                {/* <h2 className={classes.titleMenu}>Walk in Approval</h2> */}
              </div>
              <img
                src={ListReservationIconMenu}
                alt="icon-menu"
                className={classes.imageIconMenu}
              />
            </ButtonBase>
            {/* <ButtonBase
              title="List Reservasi Approval"
              className={[classes.btnCard, classes.reserveBtn].join(" ")}
              onClick={() => navigate(`${ROUTES.approver}/approval`)}
            >
              <div className="textContainer">
                <div className="icon">
                  <Reservation />
                </div>
                List Reservasi Approval
              </div>
            </ButtonBase> */}
          </div>
        );
      default:
        return (
          <div className={classes.btnContainer}>
            <p>Mohon Tunggu , Data sedang dalam proses...</p>
          </div>
        );
    }
  };

  return (
    <PageContainer className={classes.container}>
      <FajarCarousel containerClassName={classes.carousel} />
      {renderButtonList()}
      <ButtonBase
        title="Logout"
        className={classes.logoutBtn}
        onClick={() => {
          try {
            logout();
            toast("Logout Berhasil", { type: "success" });
          } catch (error) {
            toast("Logout gagal!", { type: "error" });
            console.error(error);
          }
        }}
      >
        Keluar
      </ButtonBase>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <p style={{ fontWeight: 600, fontSize: "12px", opacity: 0.5 }}>
          © 2022 Dayasapaper.  All rights reserved.
        </p>
      </div>
    </PageContainer>
  );
};

export default HomePage;
