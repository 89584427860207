import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AClientCode } from "../../types/client";

export interface ClientState {
  clientData: AClientCode[];
}

const initialState: ClientState = {
  clientData: [],
};

export const clientSlice = createSlice({
  name: "CLIENT_SLICE",
  initialState,
  reducers: {
    storeClientData: (state, payload: PayloadAction<AClientCode[]>) => {
      state.clientData = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default clientSlice.reducer;
export const { storeClientData } = clientSlice.actions;
