import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { userstoreAPI } from "./UserStore/userstoreAPI";
import userstore from "./UserStore/userstore";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import channelstore from "./ChannelStore/channelstore";
import { channelstoreAPI } from "./ChannelStore/channelstoreAPI";
import scheduleStore from "./ScheduleStore/schedulestore";
import reservationStore from "./ReservationStore";
import vendorStore from "./VendorStore";
import userTypesStore from "./UserTypesStore";
import { schedulestoreAPI } from "./ScheduleStore/schedulestoreAPI";
import { truckstoreAPI } from "./TruckStore/truckstoreAPI";
import { vendorstoreAPI } from "./VendorStore/vendorstoreAPI";
import { materialstoreAPI } from "./MaterialStore/materialstoreAPI";
import { reservationstoreAPI } from "./ReservationStore/reservationstoreAPI";
import { walkinReservationStoreAPI } from "./ReservationStore/walkinReservationStoreAPI";
import registrationStore from "./RegistrationStore";
import clientStore from "./ClientStore";
import { userTypeStoreAPI } from "./UserTypesStore/userTypesStoreAPI";
import { guestReservationstoreAPI } from "./ReservationStore/guestReservationAPI";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const store = configureStore({
  reducer: {
    userstore,
    channelstore,
    registrationStore,
    reservationStore,
    scheduleStore,
    userTypesStore,
    vendorStore,
    clientStore,
    [channelstoreAPI.reducerPath]: channelstoreAPI.reducer,
    [userTypeStoreAPI.reducerPath]: userTypeStoreAPI.reducer,
    [materialstoreAPI.reducerPath]: materialstoreAPI.reducer,
    [reservationstoreAPI.reducerPath]: reservationstoreAPI.reducer,
    [schedulestoreAPI.reducerPath]: schedulestoreAPI.reducer,
    [truckstoreAPI.reducerPath]: truckstoreAPI.reducer,
    [userstoreAPI.reducerPath]: userstoreAPI.reducer,
    [vendorstoreAPI.reducerPath]: vendorstoreAPI.reducer,
    [walkinReservationStoreAPI.reducerPath]: walkinReservationStoreAPI.reducer,
    [guestReservationstoreAPI.reducerPath]: guestReservationstoreAPI.reducer,
  },
  middleware: (getMiddlewares) => {
    let middlewares = getMiddlewares().concat([
      channelstoreAPI.middleware,
      materialstoreAPI.middleware,
      reservationstoreAPI.middleware,
      schedulestoreAPI.middleware,
      truckstoreAPI.middleware,
      userstoreAPI.middleware,
      userTypeStoreAPI.middleware,
      vendorstoreAPI.middleware,
      walkinReservationStoreAPI.middleware,
      guestReservationstoreAPI.middleware,
    ]);

    return middlewares;
  },
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => {
  return useDispatch<AppDispatch>();
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
