import React from "react";
import { createUseStyles } from "react-jss";
import { Card, CardBody } from "reactstrap";
import { IoIosArrowForward } from "react-icons/io";
import { COLORS } from "../../assets/theme";
import { format } from "date-fns";
import { normalDateFormat } from "../../types/dateformat";
import id from "date-fns/locale/id";
import useTagButtonStyles from "./TagButton/useTagButtonStyles";
import { small } from "../../assets/breakpoints";
import { ButtonBase, makeStyles } from "@material-ui/core";

interface Props {
  category?: string;
  vehicleNumber?: string;
  showStatusApproval?: boolean;
  statusApproval?: string;
  onClickTicket?: () => void;
  ticketLabel: string;
  ticketDate: string;
  ticketStartTime?: string;
  ticketEndTime?: string;
  ticketActive?: boolean;
  isHistory?: boolean;
  tags: { desc: string; color: string }[];
}

const customCardStyle = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
    border: "1px solid " + COLORS.gradientGray_1,
    borderRadius: "8px",
    display: "block",
    marginBottom: 16,
    background: COLORS.gradientGreen_4,
    padding: 8,
    "&.inactive": {
      background: COLORS.gray_1,
    },
  },
  cardContainer: {
    border: "none",
    background: COLORS.gradientGreen_4,
  },
  container: {
    display: "flex",
    background: COLORS.gradientGreen_4,
    "&.inactive": {
      background: COLORS.gray_1,
    },
    border: "1px solid " + COLORS.gradientGray_1,
    borderRadius: "8px",
    marginBottom: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cardBodyContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px",
    paddingLeft: "20px",
    textAlign: "left",
    "&.inactive": {
      background: COLORS.gray_1,
    },
  },
});

const useStyles = createUseStyles({
  container: {
    display: "flex",
    background: ({ active }: { active: boolean }) =>
      active === false ? COLORS.gray_1 : COLORS.gradientGreen_4,
    border: "1px solid " + COLORS.gradientGray_1,
    borderRadius: "8px",
    marginBottom: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  ticketText: {
    fontSize: "12px",
    fontFamily: "Raleway",
    fontWeight: 600,
    width: "100%",
    color: ({ active }: { active: boolean }) =>
      active === false ? COLORS.gray_2 : COLORS.black_1,
    [small]: {
      width: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  subContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  chipsContainer: {
    height: "25px",
    borderRadius: "15px",
    borderWidth: "1px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  chipsText: {
    color: "white",
    fontFamily: "Raleway",
    fontSize: "10px",
  },
});

const Ticket: React.FunctionComponent<Props> = ({
  onClickTicket,
  ticketDate,
  ticketEndTime,
  ticketLabel,
  ticketStartTime,
  tags,
  ticketActive,
  isHistory,
}) => {
  const styles = useStyles({ active: ticketActive as boolean });
  const tag_classes = useTagButtonStyles();
  const cardClasses = customCardStyle();

  return (
    <ButtonBase
      onClick={() => onClickTicket && onClickTicket()}
      className={[cardClasses.root].join(" ")}
    >
      <Card className={cardClasses.cardContainer}>
        <CardBody className={[cardClasses.cardBodyContainer].join(" ")}>
          <div style={{ flex: 1, width: "100%" }}>
            <p
              className={styles.ticketText}
              style={{
                fontWeight: 700,
                color:
                  isHistory === true || ticketActive === false
                    ? COLORS.gray_2
                    : COLORS.black_1,
              }}
            >
              Ticket Id: {ticketLabel}
            </p>
            <p
              style={{
                fontSize: 11,
                fontFamily: "Raleway",
                fontWeight: 600,
                color:
                  isHistory === true || ticketActive === false
                    ? COLORS.gray_2
                    : COLORS.black_1,
              }}
            >
              {format(new Date(ticketDate), normalDateFormat, { locale: id })}
              {ticketStartTime &&
                ticketEndTime &&
                `, ${ticketStartTime} - ${ticketEndTime}`}
            </p>
            <div
              className={tag_classes.tagContainer}
              style={{ padding: 0, gap: "4px" }}
            >
              {tags.map(({ desc, color }) => {
                return (
                  <button
                    key={desc}
                    className={tag_classes.tag}
                    style={{
                      backgroundColor: color,
                      color:
                        ticketActive === false ? COLORS.gray_1 : COLORS.white,
                    }}
                  >
                    {desc}
                  </button>
                );
              })}
            </div>
          </div>
          <button
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: "30px",
              backgroundColor: "transparent",
              border: 0,
            }}
          >
            <IoIosArrowForward size={15} />
          </button>
        </CardBody>
      </Card>
    </ButtonBase>
  );
};

export default Ticket;
