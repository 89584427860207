import { ButtonBase } from "@material-ui/core";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import { useHomePageStyles } from "../../../pages/HomePage";
import CustomModal from "./CustomModal";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  toggleChangePasswordModal: () => void;
  toggleEditAccountModal: () => void;
};

const EditAccountorChangePasswordModal = (props: Props) => {
  const { isOpen, toggle, toggleChangePasswordModal, toggleEditAccountModal } =
    props;
  const classes = useHomePageStyles();
  const modal_classes = useModalStyles();
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      className={modal_classes.modal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <ButtonBase
          title="Edit Account"
          className={[classes.btnCard, classes.reserveListBtn].join(" ")}
          style={{ marginTop: 0, textAlign: "center", height: 56 }}
          onClick={() => {
            toggleEditAccountModal();
          }}
        >
          <span style={{ width: "100%" }}>Edit Akun</span>
        </ButtonBase>
        <ButtonBase
          title="Change Password"
          className={[classes.btnCard, classes.walkinBtn].join(" ")}
          style={{ marginTop: 8, textAlign: "center", height: 56 }}
          onClick={() => {
            toggleChangePasswordModal();
          }}
        >
          <span style={{ width: "100%" }}>Ubah Password</span>
        </ButtonBase>
      </div>
    </CustomModal>
  );
};

export default EditAccountorChangePasswordModal;
