import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../config/nav";
import useUsertype from "../../hooks/auth/useUsertype";
import { KnownUsertype } from "../../types/user";

interface Props {}

const Redirector: React.FunctionComponent<Props> = (props: Props) => {
  const to = useDefaultRoute();
  return <Navigate replace to={to} />;
};

export default Redirector;

export const getDefaultRoute = (usertype?: KnownUsertype) => {
  switch (usertype) {
    case "approver":
      return ROUTES.approver;
    case "officer":
      return ROUTES.officer;
    case "user":
      return ROUTES.user;
    case "superadmin":
      return ROUTES.superadmin;
    //   return ManagementRoutes.devices;
    // case "operator":
    // case "user":
    default:
      return ROUTES.loginpage;
    // return DashboardRoutes.energyMeter;
  }
};

export const useDefaultRoute = () => {
  const usertype = useUsertype();
  return React.useMemo(() => {
    return getDefaultRoute(usertype);
  }, [usertype]);
};
