import React, {
  ChangeEvent,
  MutableRefObject,
  ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoIosArrowRoundBack, IoIosClose, IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import { Input } from "reactstrap";
import AddButton from "../components/atoms/AddButton";
import { AUserData } from "../types/user";
import { ChannelOpt } from "../types/channel";
import useChannelOptions from "../hooks/channels/useChannelOptions";
import UserCard from "./AdminPage/Account/UserCard";
import { useGetUserListQuery } from "../redux/UserStore/userstoreAPI";
import { useAdminAccountPageStyle } from "./AdminPage/ManageAccount/style";
import AccountFormModal from "../components/atoms/Modals/forms/AccountFormModal";
import EditAccountorChangePasswordModal from "../components/atoms/Modals/EditAccountorChangePasswordModal";
import ChangePasswordModal from "../components/atoms/Modals/forms/ChangePasswordModal";
import { ButtonBase, CircularProgress, makeStyles } from "@material-ui/core";
import useLastElementInfiniteScroll from "../hooks/useLastElementInfiniteScroll";

interface Props {}

export const dummyChannelApproverPosition: OptionType[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
];

interface OptionType {
  readonly value: number;
  readonly label: string;
}

const customChipStyle = makeStyles({
  root: {
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    height: 28,
    width: "fit-content",
    marginRight: "1rem",
    borderRadius: 16,
    fontFamily: "Raleway",
    fontSize: 11,
    fontWeight: 600,
    background: "transparent",
    border: 1,
    borderStyle: "solid",
    borderColor: COLORS.gray,
    color: COLORS.black_1,
    whiteSpace: "nowrap",
    "&.pick": {
      background: COLORS.gradientBlue_3,
      border: 0,
      color: "white",
    },
  },
});

const AdminManageAccountPage: React.FunctionComponent<Props> = () => {
  const [optPick, setOptPick] = React.useState<ChannelOpt | undefined>();
  const [idPick, setIdPick] = React.useState<number>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showSwitchModal, setShowSwitchModal] = React.useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showSearchText, setShowSearchText] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const navigate = useNavigate();
  const classes = useAdminAccountPageStyle();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const {
    data: userData,
    refetch,
    isFetching,
    isLoading,
  } = useGetUserListQuery(
    searchInput
      ? {
          page: page ?? 1,
          take: limit,
          vendorName: searchInput ? searchInput : undefined,
        }
      : {
          page: page ?? 1,
          take: limit,
          channelId: optPick?.value,
        }
  );
  const loading = isFetching || isLoading;
  const { arr: channelList } = useChannelOptions();
  const chipStyleClasses = customChipStyle();

  const lastElementRef = useLastElementInfiniteScroll({
    isFetching,
    page,
    meta: userData?.meta,
    setLimit,
    limit,
  });

  const handleUserCardPicked = (id: number) => {
    setShowSwitchModal(true);
    setIdPick(id);
  };

  const toggleSwitchAccountModal = React.useCallback(() => {
    setShowSwitchModal((prev) => !prev);
  }, []);

  const toggleChangePasswordModal = React.useCallback(() => {
    setShowChangePasswordModal((prev) => !prev);
  }, []);

  const toggleEditAccountModal = React.useCallback(() => {
    setShowEditModal((prev) => !prev);
  }, []);

  const pickedAccount = React.useMemo(() => {
    if (userData && idPick && showSwitchModal) {
      return userData.entities.find(({ id }) => id === idPick);
    }
    return undefined;
  }, [idPick, userData, showSwitchModal]);

  const userDataList = useMemo(() => {
    if (!userData) return;
    const returnVal: AUserData[] = [];
    userData.entities.forEach((item) => returnVal.push(item));
    let filtered = returnVal.filter((item) => {
      return (
        item.channel?.channel === optPick?.channel ||
        item?.relationapproverchannel?.find(
          (item) => item.channel?.channel === optPick?.channel
        )
      );
    });
    if (optPick?.channel === undefined && searchInput) {
      return returnVal.filter(
        (item) =>
          item.username
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase()) ||
          item.vendor?.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
      );
    } else if (optPick?.channel === undefined) {
      return returnVal;
    } else {
      return filtered;
    }
  }, [userData, optPick]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchInput(e.target.value);
      setPage(1);
      setLimit(10);
    }, 1000);
  };

  return (
    <PageContainer
      className={classes.container}
      headerNavigationContent={
        <>
          {showSearchText === false ? (
            <>
              <div className={classes.headerSideContainer}>
                <button
                  className={classes.backButton}
                  onClick={() => navigate(-1)}
                >
                  <IoIosArrowRoundBack size={24} />
                </button>
              </div>
              <div className={classes.headerTitleContainer}>
                <span className={classes.textTitle}>Manajemen Akun</span>
              </div>
              <div className={classes.headerSideContainer}>
                <button className={classes.editButton}>
                  <IoMdSearch
                    size={22}
                    color={COLORS.black_1}
                    onClick={() => setShowSearchText(!showSearchText)}
                  />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className={classes.searchWrapper} style={{ width: "100%" }}>
                <Input
                  id="search-account"
                  name="search-account"
                  placeholder="Cari akun atau vendor..."
                  type="text"
                  onChange={handleSearch}
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",
                    borderBottom: `1px solid ${COLORS.gray_1}`,
                    boxShadow: "none",
                    borderRadius: 0,
                  }}
                />
                <IoIosClose
                  cursor={"pointer"}
                  size={24}
                  color={COLORS.black_1}
                  onClick={() => {
                    setSearchInput("");
                    setShowSearchText(!showSearchText);
                  }}
                />
              </div>
            </>
          )}
        </>
      }
      headerContent={
        <div className={classes.carouselContainer}>
          <ButtonBase
            className={[
              chipStyleClasses.root,
              typeof optPick === "undefined" ? "pick" : undefined,
            ].join(" ")}
            onClick={() => {
              setOptPick(undefined);
              setPage(1);
              setLimit(10);
            }}
          >
            {"Semua"}
          </ButtonBase>
          {channelList
            .filter((item) => item.active === true)
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((channel) => {
              return (
                <ButtonBase
                  className={[
                    chipStyleClasses.root,
                    optPick?.id === channel.id ? "pick" : undefined,
                  ].join(" ")}
                  onClick={() => {
                    setOptPick(channel);
                    setPage(1);
                    setLimit(10);
                  }}
                >
                  {channel.description}
                </ButtonBase>
              );
            })}
        </div>
      }
      withBackground
    >
      <div style={{ paddingTop: 16, paddingBottom: 16, minHeight: "100vh" }}>
        <>
          {optPick !== undefined
            ? userDataList?.map((aUser) => {
                return (
                  <div ref={lastElementRef} key={aUser.id}>
                    <UserCard
                      key={aUser.id}
                      user={aUser}
                      onClick={() => {
                        handleUserCardPicked(aUser.id);
                      }}
                    />
                  </div>
                );
              })
            : userDataList?.map((aUser) => {
                return (
                  <div ref={lastElementRef} key={aUser.id}>
                    <UserCard
                      user={aUser}
                      onClick={() => {
                        handleUserCardPicked(aUser.id);
                      }}
                    />
                  </div>
                );
              })}
        </>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={18}
              color="inherit"
              style={{ color: COLORS.blue_1 }}
            />
          </div>
        )}
        {userDataList?.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada akun yang ditemukan</span>
          </div>
        )}
      </div>
      <AddButton
        onClick={() => {
          setShowModal(true);
        }}
      />
      <AccountFormModal
        onSuccess={() => refetch()}
        showModal={showModal}
        toggle={() => setShowModal((prev) => !prev)}
        setShowModal={setShowModal}
        toggleSwitchModal={() => {}}
        isEditing={false}
        selectedAccount={undefined}
      />
      <EditAccountorChangePasswordModal
        isOpen={showSwitchModal}
        toggle={toggleSwitchAccountModal}
        toggleChangePasswordModal={toggleChangePasswordModal}
        toggleEditAccountModal={toggleEditAccountModal}
      />
      {pickedAccount && (
        <>
          <ChangePasswordModal
            isOpen={showChangePasswordModal}
            toggle={() => {
              toggleSwitchAccountModal();
              toggleChangePasswordModal();
            }}
            pickedAccount={pickedAccount}
          />
          <AccountFormModal
            onSuccess={() => refetch()}
            showModal={showEditModal}
            setShowModal={setShowEditModal}
            isEditing={true}
            toggleSwitchModal={toggleSwitchAccountModal}
            toggle={() => {
              toggleSwitchAccountModal();
              toggleEditAccountModal();
            }}
            selectedAccount={pickedAccount}
          />
        </>
      )}
    </PageContainer>
  );
};

export default AdminManageAccountPage;
