import React from "react";
import ReactDOM from "react-dom";
import { Modal, ModalProps } from "reactstrap";

interface Props extends ModalProps {
  children?: React.ReactNode;
}

const CustomModal: React.FunctionComponent<Props> = ({
  isOpen,
  toggle,
  children,
  ...rest
}: Props) => {
  if (!isOpen) return null;
  const modal = (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {children}
    </Modal>
  );
  return ReactDOM.createPortal(modal, document.getElementById("portal")!);
};

export default CustomModal;
