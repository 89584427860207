import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import { AMaterial } from "../../types/material";
import { BASIC_URL } from "../basicurl";

export const materialstoreAPI = createApi({
  reducerPath: "materialstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/material`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["MaterialList"],
  endpoints: (builder) => {
    return {
      getMaterials: builder.query<AMaterial[], {}>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: () => ["MaterialList"],
        transformResponse: (res: BackendDataShape<AMaterial[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetMaterialsQuery,
  util: { resetApiState: resetMaterialstoreAPI },
} = materialstoreAPI;
