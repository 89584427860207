import React, { useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { IoIosClose, IoMdSearch } from "react-icons/io";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import { useNavigate } from "react-router-dom";
import { AChannel, AChannelWithColor } from "../types/channel";
import Header from "../components/atoms/Header";
import { Input } from "reactstrap";
import AddButton from "../components/atoms/AddButton";
import ManageChannelFormModal from "../components/atoms/Modals/forms/ManageChannelFormModal";
import { useGetChannelsQuery } from "../redux/ChannelStore/channelstoreAPI";
import { ButtonBase, CircularProgress, makeStyles } from "@material-ui/core";

interface Props {}

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    searchWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    channelListContainer: {
      paddingBottom: "200px",
      marginTop: "3vh",
      overflowY: "auto",
    },
    modalHeaderContainer: {
      flexDirection: "row",
      justifyContent: "center",
      display: "flex",
      paddingTop: "10px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    modalSideHeader: {
      flexBasis: "20px",
      display: "flex",
    },
    modalHeaderTitle: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
      fontFamily: "Raleway",
      fontSize: "14px",
      fontWeight: 600,
    },
    channelCardContainer: {
      height: 56,
      width: "100%",
      marginBottom: "2vh",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Raleway",
      fontSize: 14,
      borderRadius: "1rem",
      background: COLORS.gradientGreen_4,
      fontWeight: 600,
      border: "1px solid " + COLORS.gradientGreen_4,
      color: COLORS.black_1,
    },
    addButtonContainer: {
      height: "50px",
      width: "50px",
      borderRadius: "25px",
      background: COLORS.gradientBlue_3,
      color: "#fff",
      border: 0,
      position: "absolute",
      right: "30px",
      bottom: "20px",
      zIndex: 99,
    },
    modalHeader: {
      marginBottom: "5vh",
      marginTop: "5vh",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  };
});

const AdminChannelListPage: React.FunctionComponent<Props> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [showSearchInput, setShowSearchInput] = React.useState<boolean>(false);
  const [pickedChannel, setPickedChannel] = React.useState<AChannel>();
  const navigate = useNavigate();
  const classes = useStyles();
  const { data: channelList, refetch, isLoading } = useGetChannelsQuery({});

  const handleShowEditModal = useCallback((e: AChannel) => {
    setPickedChannel(e);
    setShowEditModal(true);
  }, []);

  const channelDataList = useMemo(() => {
    if (channelList === undefined) return;
    const returnVal: AChannelWithColor[] = [];
    channelList.forEach((item) => {
      returnVal.push(item);
    });
    const filtered = returnVal.filter(
      (item) =>
        item.description.includes(searchInput) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase())
    );
    return filtered;
  }, [channelList, searchInput]);

  return (
    <PageContainer
      headerNavigationContent={
        <>
          {showSearchInput === false ? (
            <>
              <Header
                title={"Manajemen Channel"}
                showBackButton={true}
                backButtonOnClick={() => navigate(-1)}
              />
              <div>
                <IoMdSearch
                  size={20}
                  cursor="pointer"
                  color={COLORS.black_1}
                  onClick={() => setShowSearchInput(!showSearchInput)}
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.searchWrapper} style={{ width: "100%" }}>
                <Input
                  id="search-channel"
                  name="search-channel"
                  placeholder="Cari nama channel..."
                  type="text"
                  onChange={(val) => setSearchInput(val.target.value)}
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "none",
                    borderBottom: `1px solid ${COLORS.gray_1}`,
                    boxShadow: "none",
                    borderRadius: 0,
                  }}
                />
                <IoIosClose
                  size={24}
                  color={COLORS.black_1}
                  cursor="pointer"
                  onClick={() => {
                    setSearchInput("");
                    setShowSearchInput(!showSearchInput);
                  }}
                />
              </div>
            </>
          )}
        </>
      }
      className={classes.container}
      withBackground
    >
      <div
        className={classes.channelListContainer}
        style={{ minHeight: "100vh" }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={18}
              color="inherit"
              style={{ color: COLORS.blue_1 }}
            />
          </div>
        ) : (
          channelDataList &&
          channelDataList
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((item) => {
              return (
                <ButtonBase
                  className={classes.channelCardContainer}
                  key={item.id}
                  style={{
                    background:
                      item.active === false
                        ? COLORS.gray_1
                        : COLORS.gradientGreen_4,
                    color:
                      item.active === false ? COLORS.gray_2 : COLORS.black_1,
                  }}
                  onClick={() => handleShowEditModal(item)}
                >
                  {item.description}
                </ButtonBase>
              );
            })
        )}
        {channelDataList?.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada channel yang ditemukan</span>
          </div>
        )}
      </div>
      <AddButton onClick={() => setShowModal(!showModal)} />
      <ManageChannelFormModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      />
      {pickedChannel && showEditModal && (
        <ManageChannelFormModal
          isOpen={showEditModal}
          isEditing={true}
          selectedChannel={pickedChannel}
          onSuccess={() => refetch()}
          toggle={() => setShowEditModal(!showEditModal)}
        />
      )}
    </PageContainer>
  );
};

export default AdminChannelListPage;
