import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BackendDataShape } from "../../types";
import {
  AReservation,
  AWEReservationParams,
  AWIReservation,
  ReservationRequestObj,
} from "../../types/reservation";
import { BASIC_URL } from "../basicurl";

export const guestReservationstoreAPI = createApi({
  reducerPath: "guestReservationstoreAPI",
  tagTypes: ["GuestReservationList", "GuestWalkinReservationList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}`,
  }),
  endpoints: (builder) => {
    return {
      getGuestReservationById: builder.query<
        AReservation[],
        Pick<ReservationRequestObj, "qrid">
      >({
        query: ({ qrid }) => {
          return {
            url: `/reservation/find/qr/${qrid}`,
            method: "GET",
          };
        },
        providesTags: () => ["GuestReservationList"],
        transformResponse: (res: BackendDataShape<AReservation[]>) => {
          return res.data;
        },
      }),
      getGuestWalkinReservationById: builder.query<
        AWIReservation[],
        Pick<AWEReservationParams, "qrid">
      >({
        query: ({ qrid }) => {
          return {
            url: `/reservationwalkin/find/qr/${qrid}`,
            method: "GET",
          };
        },
        providesTags: () => ["GuestWalkinReservationList"],
        transformResponse: (res: BackendDataShape<AWIReservation[]>) => {
          return res.data;
        },
      }),
    };
  },
});

export const {
  useGetGuestReservationByIdQuery,
  useGetGuestWalkinReservationByIdQuery,
  util: { resetApiState: resetGuestReservationState },
} = guestReservationstoreAPI;
