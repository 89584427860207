import React, { CSSProperties } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import useButtonStyles from "../../../hooks/styles/useButtonStyles";

interface Props {
  containerStyle?: CSSProperties;
  containerClassName?: string;
  file: File;
  withDeleteIcon?: boolean;
  onDelete?: () => void;
  // CustomComponent?: JSX.Element
}

const ImageViewer = ({
  containerStyle,
  containerClassName,
  file,
  withDeleteIcon,
  onDelete,
}: Props) => {
  const btnClasses = useButtonStyles();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [imgURL, setImgURL] = React.useState<string | null>(null);

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onloadstart = () => {
      setIsLoading(true);
    };
    reader.onload = (e) => {
      function isAbv(file: any): file is ArrayBuffer {
        return file instanceof ArrayBuffer;
      }
      let res = e.target?.result as string | undefined;

      if (isAbv(res)) {
        // ignore
      } else {
        setImgURL(res ?? null);
        setIsLoading(false);
      }
    };

    reader.readAsDataURL(file);
    return () => {
      reader.abort();
    };
  }, [file]);

  return (
    <div
      className={containerClassName}
      style={{
        position: "relative",
        ...containerStyle,
      }}
    >
      {!isLoading && imgURL && (
        <>
          {withDeleteIcon && (
            <button
              className={btnClasses.button}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "transparent",
              }}
              type="button"
              onClick={onDelete}
            >
              <AiFillCloseCircle size={25} color="gray" />
            </button>
          )}
          <img
            alt={file.name}
            src={imgURL}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        </>
      )}
    </div>
  );
};

export default ImageViewer;
