import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import { ATruck, ATruckOpt } from "../../types/truck";
import { BASIC_URL } from "../basicurl";

export const truckstoreAPI = createApi({
  reducerPath: "truckstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/truck`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["TruckList"],
  endpoints: (builder) => {
    return {
      getTrucks: builder.query<ATruck[], {}>({
        query: () => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["TruckList"],
        transformResponse: (res: BackendDataShape<ATruck[]>) => {
          return res.data;
        },
      }),
      getTrucksOptions: builder.query<ATruckOpt[], {}>({
        query: () => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }
          return {
            url: "/find",
            method: "GET",
          };
        },
        providesTags: () => ["TruckList"],
        transformResponse: (res: BackendDataShape<ATruckOpt[]>) => {
          return res.data.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.nopol,
            };
          });
        },
      }),
    };
  },
});

export const {
  useGetTrucksQuery,
  useGetTrucksOptionsQuery,
  util: { resetApiState: resetTruckstoreAPI },
} = truckstoreAPI;
