import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { changePassword } from "../api/user/resetPassword";
import { FajarPaperSVG } from "../assets/Icons";
import Truck from "../components/atoms/Truck";
import PageContainer from "../components/PageContainer/PageContainer";
import useChangePasswordValidationSchema from "../hooks/auth/useChangePasswordValidationSchema";
import { ErrorMessageBackendDataShape } from "../types";
import useLoginStyles from "./LoginPage/useLoginStyles";
import * as yup from "yup";

type Props = {};

const ForgotPasswordPage = (props: Props) => {
  const classes = useLoginStyles();
  const [newPassword, setNewPassword] = useState<string>("");
  const [retypeNewPassword, setRetypeNewPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { schema: changePasswordValidationSchema } =
    useChangePasswordValidationSchema();
  const handleSubmitChangePassword: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        if (!newPassword && retypeNewPassword) return;
        const data = {
          newPassword,
          retypeNewPassword,
        };
        setIsLoading(true);
        changePasswordValidationSchema
          .validate(data)
          .then(() => {
            changePassword(newPassword, "")
              .then(() => {
                toast.success("Password berhasil diubah.");
                setIsLoading(false);
                navigate("/login");
              })
              .catch((err: ErrorMessageBackendDataShape) => {
                if (err) {
                  toast.error(err.data.message);
                } else {
                  toast.error(
                    "Error saat mengubah password. Silahkan coba kembali."
                  );
                }
                setIsLoading(false);
              });
          })
          .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            if (errors) {
              toast.error(errors.errors.join(","));
            } else {
              toast.error(
                "Error! saat mengubah password. Silahkan coba kembali."
              );
            }
          });
      },
      [newPassword, retypeNewPassword]
    );
  return (
    <PageContainer>
      <Form
        onSubmit={handleSubmitChangePassword}
        className={classes.formContainer}
        id="reset_password"
      >
        <div style={{ textAlign: "center", marginBottom: 32, marginTop: 24 }}>
          <h3 style={{ marginBottom: 12, fontWeight: 600 }}>
            Atur Ulang Password
          </h3>
          <span>Masukkan Password baru Anda.</span>
        </div>
        <FormGroup className="mb-5">
          <Label for="new-password">Password Baru</Label>
          <Input
            className={classes.input}
            name="new-password"
            placeholder="Password Baru..."
            type="password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </FormGroup>
        <FormGroup className="mb-5">
          <Label for="retype-new-password">Ketik Ulang Password Baru</Label>
          <Input
            className={classes.input}
            name="retype-new-password"
            placeholder="Ketik Ulang Password Baru..."
            type="password"
            value={retypeNewPassword}
            onChange={(e) => {
              setRetypeNewPassword(e.target.value);
            }}
          />
        </FormGroup>
        <button
          type="submit"
          disabled={isLoading}
          form="reset_password"
          className={classes.loginBtn}
          // onClick={() => handleSubmitChangePassword()}
        >
          {isLoading ? "LOADING..." : "ATUR ULANG KATA SANDI"}
        </button>
      </Form>
      <Truck className={classes.truck} />
    </PageContainer>
  );
};

export default ForgotPasswordPage;
