import React, { CSSProperties, ReactNode } from "react";
import { usePageContainerStyles } from "./usePageContainerStyles";

interface Props {
  children?: ReactNode;
  className?: string;
  /**
   * background will be colored if true
   */
  withBackground?: boolean;
  headerNavigationContent?: ReactNode;
  headerContent?: ReactNode;
  headerBackground?: string;
  headerContainerStyle?: CSSProperties;
  mainContainerStyle?: CSSProperties;
  headerNavigationContentStyle?: CSSProperties;
}

const PageContainer: React.FunctionComponent<Props> = ({
  children,
  className,
  withBackground,
  headerNavigationContent,
  headerNavigationContentStyle,
  headerContent,
  headerBackground,
  headerContainerStyle,
  mainContainerStyle,
}) => {
  const classes = usePageContainerStyles();
  return (
    <>
      <div
        className={[
          classes.root,
          withBackground ? "withBackground" : undefined,
          className,
        ].join(" ")}
      >
        {headerNavigationContent && (
          <header
            className={classes.headerContainer}
            style={{
              background: headerBackground,
              ...headerContainerStyle,
            }}
          >
            <nav
              className={classes.headerNavigationContainer}
              style={{ ...headerNavigationContentStyle }}
            >
              {headerNavigationContent}
            </nav>
            <div>{headerContent}</div>
          </header>
        )}
        {children && (
          <main
            className={classes.mainContainer}
            style={{
              ...mainContainerStyle,
            }}
          >
            {children}
          </main>
        )}
      </div>
      {withBackground && <div className={classes.background}></div>}
    </>
  );
};

export default PageContainer;
