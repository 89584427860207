export const COLORS = {
  white: "#FFFFFF",
  blue_1: "#2980B9",
  blue_2_rgb: "rgb(80 129 139)",
  blue_3_rgb: "rgb(7 70 141)",
  blue_4_rgb: "rgb(67 191 204)",
  blue_5_rgb: "rgb(81 91 146)",
  green: "#1ABC9C",
  green_1: "#16A085",
  green_2: "#27AE60",
  green_3_rgb: "rgb(103 177 117)",

  green_3: "#20C997",

  black_1: "#34495E",
  gray: "#A5AEC2",
  gray_1: "#C4CDE0",
  gray_2: "#687484",
  gray_3: "rgb(88 101 129)",

  purple: "#9B59B6",
  purple_1_rgb: "rgb(223 30 169)",
  purple_2_rgb: "rgb(145 101 209)",

  light_gray_1: "#EEF1F8",

  brown: "#7A5547",
  brown_1_rgb: "rgb(181 76 3)",

  red_1: "#FC5A5A",
  red_2: "#E74C3C",

  orange_1: "#E67E22",
  orange_2: "#E64D22",
  yellow_1_rgb: "rgb(244 168 9)",
  gradientBlue_1: "linear-gradient(90deg, #2980B9 0%, #6DD5FA 100%)",
  gradientBlue_2: "linear-gradient(90deg, #024079 0%, #0595E6 100%)",
  gradientGreen_1:
    "linear-gradient(0deg, #93E0D1, #93E0D1), linear-gradient(90deg, #C9F1FF 0%, #D8FBD7 99.13%)",
  gradientGreen_2: "linear-gradient(90deg, #097B72 1.46%, #06C750 100%)",
  gradientGreen_3: "linear-gradient(90deg, #11998E 0%, #38EF7D 97.25%)",
  gradientGreen_4: "linear-gradient(90deg, #C9F1FF 0%, #D8FBD7 99.13%)",
  gradientBlue_3: "linear-gradient(90deg, #0B50A6 1.46%, #00C2FF 100%)",
  gradientBlueGreen_1: "linear-gradient(90deg, #106DC8 0%, #07BB63 100%)",
  gradientGray_1:
    "linear-gradient(90deg, #A7B4D5 0%, #D4D9E2 100%), linear-gradient(0deg, #C4CDE0, #C4CDE0)",
  gradientPurple_1: "linear-gradient(90deg, #B91D73 1.46%, #F852C5 100%)",
  gradientOrange_1: "linear-gradient(90deg, #D8330B 1.46%, #FE7A4A 100%)",
  gradientAsphalt_1: "linear-gradient(90deg, #16222A 1.46%, #477A94 100%)",
  gradientRed_1: "linear-gradient(90deg, #FF416C 0%, #FF4B2B 98.93%)",
  gradientAsphalt_2: "linear-gradient(90deg, #43C6AC 1.46%, #191654 100%)",
} as const;
