import { Children, ReactNode } from "react";
import Select, {
  Props as SelectProps,
  components,
  GroupBase,
  createFilter,
  OptionsOrGroups,
  Options,
} from "react-select";
import { FixedSizeList as List } from "react-window";
const BASE_HEIGHT = 35;

interface PureInputSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends SelectProps<Option, IsMulti, Group> {
  isLargeDataList?: boolean;
}
interface MenuListProps<
  Option,
  Group extends GroupBase<Option> = GroupBase<Option>
> {
  maxHeight: string | number;
  children?: ReactNode | any;
  options: OptionsOrGroups<Option, Group>;
  getValue: () => Options<Option>;
}

function MenuList<Option, Group extends GroupBase<Option> = GroupBase<Option>>({
  children,
  getValue,
  maxHeight,
  options,
}: MenuListProps<Option, Group>) {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * BASE_HEIGHT;
  return (
    <List
      height={maxHeight}
      itemCount={Children.toArray(children).length}
      itemSize={BASE_HEIGHT}
      width={"100%"}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

function PureInputSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  noOptionsMessage,
  styles,
  placeholder,
  isLargeDataList,
  ...rest
}: PureInputSelectProps<Option, IsMulti, Group>) {
  return (
    <Select
      placeholder={placeholder ? placeholder : "Pilih..."}
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        DropdownIndicator: components.DropdownIndicator,
        IndicatorSeparator: () => null,
        MenuList: isLargeDataList ? MenuList : components.MenuList,
      }}
      noOptionsMessage={
        noOptionsMessage
          ? noOptionsMessage
          : ({ inputValue }) => {
              if (inputValue) return `'${inputValue}' tidak ditemukan!`;
              return "Tidak ada pilihan!";
            }
      }
      styles={{
        control: (curStyle, { isDisabled }) => {
          return {
            ...curStyle,
            backgroundColor: isDisabled ? "#F2F2F2" : "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            border: "1px solid #DDDDDD",
            backdropFilter: "blur(10)",
          };
        },
        input: (curStyle) => ({
          ...curStyle,
          color: "#34495E",
          fontSize: 13,
          fontWeight: 600,
        }),
        singleValue: (styles) => ({
          ...styles,
          color: "rgb(52, 73, 94)",
          fontSize: 13,
          fontWeight: 600,
        }),
        valueContainer: (styles) => ({
          ...styles,
          paddingLeft: "1.6rem",
          fontSize: 13,
          fontWeight: 600,
        }),
        placeholder: (curStyles) => ({
          ...curStyles,
          fontSize: 13,
          fontWeight: 600,
        }),
        multiValue: (styles) => {
          return {
            ...styles,
            backgroundColor: "#18A0FB",
            borderRadius: "16px",
            padding: "4px 8px",
            color: "white",
            fontSize: 13,
            fontWeight: 600,
          };
        },
        multiValueLabel: (styles) => ({
          ...styles,
          color: "#FFFFFF",
        }),
        option: (
          styles,
          { isSelected, isFocused, innerProps: { onMouseMove, onMouseOver } }
        ) => {
          return {
            ...styles,
            color: isFocused ? "#fff" : "#34495E",
            backgroundColor: isFocused ? "#34495E" : "#fff",
            fontSize: 13,
            fontWeight: 600,
          };
        },
        dropdownIndicator: (styles) => ({
          ...styles,
          padding: 0,
          marginLeft: "5px",
          marginRight: "5px",
        }),
        noOptionsMessage: (styles, state) => ({
          ...styles,
          color: "red",
        }),
        menu: (styles) => ({
          ...styles,
          borderRadius: 0,
          marginTop: "5px",
          backgroundColor: "#FFFFFF",
        }),
        menuList: (styles) => ({
          ...styles,
          padding: 0,
        }),
        container: (styles) => ({
          ...styles,
          width: "fill-available",
        }),
        ...styles,
      }}
      {...rest}
    />
  );
}

export default PureInputSelect;
