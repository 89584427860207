import React from "react";
import { useAppCookies } from ".";
import { useAppSelector } from "../../redux";

type Props = {};

/**
 * for `user` it shouldnt be `null`. for `officer` it will be `null`
 */
const useUserChannelId = () => {
  const [{ channelId }] = useAppCookies(["channelId"]);
  const channel =
    useAppSelector(({ userstore }) => userstore.userData?.channelId) ||
    (channelId as number | undefined);
  return channel;
};

export default useUserChannelId;
