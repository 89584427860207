import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AVendor } from "../../types/vendor";

// export interface ChannelState {
//   channelsWithColor: ADummyChannelWithColor[];
// }

// const initialState: ChannelState = {
//   channelsWithColor: [],
// };
export interface VendorState {
  vendorData: AVendor[];
}

const initialState: VendorState = {
  vendorData: [],
};

export const vendorSlice = createSlice({
  name: "VENDOR_SLICE",
  initialState,
  reducers: {
    storeVendorData: (state, payload: PayloadAction<AVendor[]>) => {
      state.vendorData = payload.payload;
    },
    //   setChannelColors: (state, action: PayloadAction<AChannel[]>) => {
    //     const { payload } = action;
    //     state.channelData = payload.map((val) => {
    //       return {
    //         ...val,
    //         color: "blue",
    //       };
    //     });
    //   },
  },
});

export default vendorSlice.reducer;
export const { storeVendorData } = vendorSlice.actions;
