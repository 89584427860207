import { useGetChannelsQuery } from "../../redux/ChannelStore/channelstoreAPI";
import { ChannelOpt, ChannelReqObj } from "../../types/channel";

type RetVal = {
  arr: ChannelOpt[];
  isLoading: boolean;
};

const useChannelOptions = (
  arg?: ChannelReqObj,
  opt?: { skip?: boolean }
): RetVal => {
  const { data, isLoading, isFetching } = useGetChannelsQuery(arg || {}, {
    skip: opt?.skip,
  });

  return {
    arr:
      data?.map((val) => {
        return {
          label: val.description,
          value: val.id,
          ...val,
        };
      }) || [],
    isLoading: isLoading || isFetching,
  };
};

export default useChannelOptions;
