import React, { Dispatch, SetStateAction } from "react";
import { BackendMetaPagination } from "../types";

type Props = {
  isFetching?: boolean;
  page: number;
  limit: number;
  setPage?: Dispatch<SetStateAction<number>>;
  setLimit?: Dispatch<SetStateAction<number>>;
  meta?: BackendMetaPagination;
};

const useLastElementInfiniteScroll = ({
  isFetching,
  page,
  meta,
  limit,
  setPage,
  setLimit,
}: Props) => {
  const observer = React.useRef<IntersectionObserver>();
  const lastElementRef = React.useCallback<(node: HTMLDivElement) => void>(
    (node) => {
      {
        if (isFetching || !page || !meta || !limit) return;
        const { pageCount } = meta;
        const isLastPage = page === pageCount;

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !isLastPage) {
            console.log("reach bottom");
            setPage && setPage((prevPageNumber) => prevPageNumber + 1);
            setLimit && setLimit((prevPageNumber) => prevPageNumber + 10);
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [isFetching, page, limit, meta, setPage, setLimit]
  );
  return lastElementRef;
};

export default useLastElementInfiniteScroll;
