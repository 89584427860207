import React from "react";
import { createUseStyles } from "react-jss";
import { IoIosArrowRoundBack } from "react-icons/io";
import { COLORS } from "../../assets/theme";

interface Props {
  showBackButton?: boolean;
  rightButton?: React.ReactNode;
  backButtonOnClick?: () => void;
  backButtonColor?: string;
  title?: string;
  headerTextColor?: string;
}

const useStyles = createUseStyles(() => {
  return {
    container: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    sideNoChildContainer: {
      flexBasis: "20px",
      display: "flex",
      alignItems: "center",
    },
    backButton: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexBasis: "20px",
      border: 0,
      backgroundColor: "transparent",
    },
    textTitle: {
      color: "black",
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: 16,
      marginBottom: 0,
      textAlign: "center",
      wordBreak: "break-word",
      letterSpacing: 0.75,
    },
    icon: {
      fontSize: "2em",
      cursor: "pointer",
    },
    activeIcon: {
      color: COLORS.blue_1,
    },
  };
});

const Header: React.FunctionComponent<Props> = ({
  backButtonOnClick,
  showBackButton,
  rightButton,
  title,
  headerTextColor,
  backButtonColor,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {showBackButton === true ? (
        <button className={classes.backButton} onClick={backButtonOnClick}>
          <IoIosArrowRoundBack size={24} color={backButtonColor} />
        </button>
      ) : (
        <div className={classes.sideNoChildContainer}></div>
      )}
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          flex: 1,
        }}
      >
        <p className={classes.textTitle} style={{ color: headerTextColor }}>
          {title}
        </p>
      </div>
      <div className={classes.sideNoChildContainer}>{rightButton}</div>
    </div>
  );
};

export default Header;
