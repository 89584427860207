import React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { ROUTES } from "../../config/nav";
import { useAppCookies } from "../../hooks/auth";
import useJWT from "../../hooks/auth/useJWT";

interface Props {}

const AuthHOC: React.FunctionComponent<Props> = () => {
  let location = useLocation();
  const jwt = useJWT();
  if (jwt && jwt.length) return <Outlet />;

  return <Navigate to={ROUTES.loginpage} state={{ from: location }} />;
};

export default AuthHOC;
