import { createUseStyles } from "react-jss";
import { COLORS } from "../../assets/theme";

export const usePageContainerStyles = createUseStyles({
  root: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  background: {
    backgroundColor: COLORS.light_gray_1,
    zIndex: -1000,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  headerContainer: {
    background: COLORS.white,
    padding: "0 1.2rem",
  },
  mainContainer: {
    marginTop: "1.2rem",
    background: COLORS.white,
    padding: "1rem 2.6rem 1rem",
    overflowY: "overlay",
    width: "100%",
  },
  headerNavigationContainer: {
    flexDirection: "row",
    flexBasis: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2.5rem 0",
  },
});
