import React from "react";
import { Document, Page, Image, Text, View } from "@react-pdf/renderer";
import { format, isPast, isYesterday } from "date-fns";
import { normalDateFormat } from "../../../types/dateformat";
import id from "date-fns/esm/locale/id";
import { AChannel } from "../../../types/channel";
import { AVendor } from "../../../types/vendor";
import { AContainer, ReservationStatus } from "../../../types/reservation";
import { FajarPaper_logo } from "../../../assets/images";
import { COLORS } from "../../../assets/theme";

type Props = {
  qr?: string;
  vendor?: AVendor;
  date: string;
  startTime?: string;
  endTime?: string;
  channel: AChannel;
  nopol: string;
  driverName: string;
  materialName?: string;
  poNum?: string;
  poLine?: number;
  photo?: any[];
  isWalkin: boolean;
  description?: string;
  deliveryNote: string;
  shipmentNumber: string;
  container: string;
  approverPosition: number;
  driverPhoneNumber?: string;
  // isNonWalkin?: boolean;
  /**
   * required when `isWalkin === true`
   */
  statusWalkin?: ReservationStatus;
};

const TicketPDFDoc = ({
  qr,
  channel,
  vendor,
  date,
  startTime,
  endTime,
  nopol,
  driverName,
  materialName,
  poNum,
  poLine,
  photo,
  isWalkin,
  statusWalkin,
  description,
  deliveryNote,
  shipmentNumber,
  container,
  approverPosition,
  driverPhoneNumber,
}: Props) => {
  const fields = React.useMemo(() => {
    let arr: { label: string; value: string }[] = [];
    channel.statusVendor.active &&
      vendor &&
      arr.push({ label: "Vendor", value: vendor.name });
    arr.push({
      label: "Tanggal",
      value: format(new Date(date), normalDateFormat, {
        locale: id,
      }),
    });
    startTime &&
      endTime &&
      arr.push({
        label: "Pukul",
        value: `${startTime} - ${endTime}`,
      });
    arr.push({
      label: "Channel",
      value: channel.description,
    });
    arr.push({
      label: "No Kendaraan",
      value: nopol,
    });
    arr.push({
      label: "Pengemudi",
      value: driverName,
    });
    channel.statusDriverPhoneNumber?.active &&
      driverPhoneNumber &&
      arr.push({
        label: "No. Pengemudi",
        value: driverPhoneNumber,
      });
    isWalkin &&
      statusWalkin &&
      arr.push({
        label: "Status",
        value:
          statusWalkin === "approved"
            ? "Disetujui"
            : statusWalkin === "rejected"
            ? "Ditolak"
            : statusWalkin === "pending" && !isYesterday(new Date(date))
            ? `Menunggu Persetujuan - Approver ${approverPosition + 1}`
            : `Kadaluarsa`,
      });
    channel.statusMaterial.active &&
      materialName &&
      arr.push({
        label: "Jenis Barang",
        value: materialName,
      });
    channel.statusPo.active &&
      poNum &&
      arr.push({
        label: "PO",
        value: poNum,
      });
    channel.statusPo.active &&
      poLine &&
      arr.push({
        label: "Line",
        value: poLine.toString(),
      });
    channel.statusContainer.active &&
      container &&
      arr.push({
        label: "No Container",
        value: container ?? "-",
      });
    channel.statusDeliveryNote.active &&
      deliveryNote &&
      arr.push({
        label: "Delivery Note",
        value: deliveryNote ?? "-",
      });
    channel.statusShipmentNumber.active &&
      shipmentNumber &&
      arr.push({
        label: "Shipment No.",
        value: shipmentNumber ?? "-",
      });

    return arr;
  }, [
    channel,
    vendor,
    date,
    startTime,
    endTime,
    nopol,
    driverName,
    materialName,
    poNum,
    driverPhoneNumber,
    shipmentNumber,
    container,
    deliveryNote,
  ]);

  const showQR = isWalkin ? statusWalkin === "approved" : true;

  return (
    <Document>
      <Page size="A5">
        <View>
          <Image
            src={FajarPaper_logo}
            style={{
              width: 80,
              marginLeft: 16,
              objectFit: "cover",
            }}
          />
        </View>
        <View style={{ flexDirection: "column" }}>
          <View style={{ width: "80%", alignSelf: "center" }}>
            {fields.map(({ label, value }, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    width: "100%",
                    color: COLORS.black_1,
                    marginBottom: 12,
                  }}
                >
                  <Text style={{ width: "30%", fontSize: 12 }}>{label}</Text>
                  <Text style={{ fontSize: 12 }}>: {value}</Text>
                </View>
              );
            })}
            {isWalkin && (
              <View
                style={{
                  flexDirection: "row",
                  display: "flex",
                  width: "100%",
                  color: COLORS.black_1,
                  marginBottom: 12,
                }}
              >
                <Text style={{ width: "30%", fontSize: 12 }}>Keterangan</Text>
                <Text
                  style={{ fontSize: 12, width: "70%", textAlign: "justify" }}
                >
                  : {description}
                </Text>
              </View>
            )}
          </View>
          {showQR && (
            <Image src={qr} style={{ width: 200, alignSelf: "center" }} />
          )}
          {photo && (
            <View
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignContent: "center",
                marginTop: 16,
                flexWrap: "wrap",
              }}
            >
              {photo.map((item) => (
                <Image
                  key={item}
                  src={`${process.env.REACT_APP_BASIC_URL}${item}`}
                  style={{
                    width: 160,
                    alignSelf: "center",
                    objectFit: "cover",
                    marginLeft: 8,
                    marginBottom: 8,
                  }}
                />
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default TicketPDFDoc;
