import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataShape } from "../../types";
import {
  ASchedule,
  AvailableQuotaReqObj,
  GroupScheduleReqObj,
  SchedulesCreateObj,
  UpdateGroupSchedule,
  UpdateSchedulesObj,
} from "../../types/schedule";
import { BASIC_URL } from "../basicurl";

export const schedulestoreAPI = createApi({
  reducerPath: "schedulestoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/schedule`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["QuotaAvailable", "ScheduleList", "GroupScheduleList"],
  endpoints: (builder) => {
    return {
      getSchedule: builder.query<ASchedule[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: ["ScheduleList"],
        transformResponse: (res: BackendDataShape<ASchedule[]>) => {
          return res.data;
        },
      }),
      getGroupSchedule: builder.query<GroupScheduleReqObj[], object>({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/group/find" + params,
            method: "GET",
          };
        },
        providesTags: ["GroupScheduleList"],
        transformResponse: (res: BackendDataShape<GroupScheduleReqObj[]>) => {
          return res.data;
        },
      }),
      getAvailableQuota: builder.query<ASchedule[], AvailableQuotaReqObj>({
        query: (obj) => {
          return {
            url: "/available",
            method: "POST",
            body: obj,
          };
        },
        providesTags: (_, __, { channelId, date }) => [
          { type: "QuotaAvailable", id: channelId },
          { type: "QuotaAvailable", id: date },
        ],
        transformResponse: (res: BackendDataShape<ASchedule[]>) => {
          return res.data;
        },
      }),
      checkAvailableQuotabyId: builder.mutation<
        BackendDataShape<boolean>,
        AvailableQuotaReqObj
      >({
        query: (obj) => {
          return {
            url: "/available",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["QuotaAvailable"],
      }),
      createSchedule: builder.mutation<unknown, SchedulesCreateObj>({
        query: (obj) => {
          return {
            url: "/create",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),
      createGroupSchedule: builder.mutation<
        unknown,
        Omit<GroupScheduleReqObj, "channel">
      >({
        query: (obj) => {
          return {
            url: "/group/create",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["GroupScheduleList"],
      }),
      updateGroupSchedule: builder.mutation<unknown, UpdateGroupSchedule>({
        query: (obj) => {
          return {
            url: "/group/manage",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["GroupScheduleList"],
      }),
      updateSchedule: builder.mutation<unknown, UpdateSchedulesObj>({
        query: (obj) => {
          return {
            url: "/manage",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["ScheduleList"],
      }),
    };
  },
});

export const {
  useGetScheduleQuery,
  useCreateScheduleMutation,
  useCreateGroupScheduleMutation,
  useUpdateScheduleMutation,
  useUpdateGroupScheduleMutation,
  useGetAvailableQuotaQuery,
  useGetGroupScheduleQuery,
  useCheckAvailableQuotabyIdMutation,
  util: { resetApiState: resetSchedulestoreAPI },
} = schedulestoreAPI;
