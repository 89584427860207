import React from "react";
import { useGetChannelsQuery } from "../../redux/ChannelStore/channelstoreAPI";

const useChannelColorExtractor = () => {
  const { data: channelList } = useGetChannelsQuery({});
  return React.useCallback(
    (id: number) => {
      return channelList?.find((val) => {
        return val.id === id;
      });
    },
    [channelList]
  );
};

export default useChannelColorExtractor;
