import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { COLORS } from "../../../assets/theme";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowRoundBack } from "react-icons/io";
import {
  GroupScheduleObj,
  GroupScheduleReqObj,
  UpdateGroupSchedule,
} from "../../../types/schedule";
import { Modal, ModalBody, Label, Input, Col, Row } from "reactstrap";
import AddButton from "../../../components/atoms/AddButton";
import { BaseIcon } from "../../../assets/Icons";
import { closeIcon_SVG } from "../../../assets/Icons/svg";
import {
  useCreateGroupScheduleMutation,
  useGetGroupScheduleQuery,
  useUpdateGroupScheduleMutation,
} from "../../../redux/ScheduleStore/schedulestoreAPI";
import { useAdminSchedulePageStyle } from "./style";
import { useGetChannelsQuery } from "../../../redux/ChannelStore/channelstoreAPI";
import useChannelOptions from "../../../hooks/channels/useChannelOptions";
import { SingleValue } from "react-select";
import { ChannelOpt } from "../../../types/channel";
import PureInputSelect from "../../../components/atoms/PureInputSelect";
import useTagButtonStyles from "../../../components/atoms/TagButton/useTagButtonStyles";
import { format, formatISO } from "date-fns";
import { BiTrash } from "react-icons/bi";
import { startOfDay } from "date-fns/esm";
import { ButtonBase, CircularProgress } from "@material-ui/core";
import CustomModal from "../../../components/atoms/Modals/CustomModal";
import useModalStyles from "../../../hooks/styles/useModalStyles";
import { ErrorMessageBackendDataShape } from "../../../types";
interface Props {}

/**
 * @todo untuk page yang terdiri dari list, style pagenya tolong disesuaikan. mungkin bisa lihat `useListContainerStyles` untuk classNames
 */
const AdminScheduleChannelListPage: React.FunctionComponent<Props> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [groupScheduleInput, setGroupScheduleInput] = React.useState<
    Partial<GroupScheduleObj>[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const currentDate = new Date();
  const [optPick, setOptPick] = React.useState<ChannelOpt | undefined>();
  const [startDate, setStartDate] = React.useState<string | Date>(new Date());
  const [endDate, setEndDate] = React.useState<string | Date>(new Date());
  const navigate = useNavigate();
  const classes = useAdminSchedulePageStyle();
  const modal_classes = useModalStyles();
  const [createGroupSchedule] = useCreateGroupScheduleMutation();
  const [updateGroupSchedule] = useUpdateGroupScheduleMutation();
  const { data: groupScheduleData } = useGetGroupScheduleQuery({
    aftertime: format(startOfDay(currentDate), "yyyy-MM-dd hh:mm aaaaa'm"),
    activeChannel: true,
  });
  const tagClasses = useTagButtonStyles();
  const [selectedChannel, setSelectedChannel] =
    React.useState<SingleValue<ChannelOpt>>();
  const { arr: channelOpts } = useChannelOptions({ active: true });

  const groupScheduleDataList = useMemo(() => {
    if (!groupScheduleData) return [];
    const returnVal: GroupScheduleObj[] = [];
    groupScheduleData.forEach((item: any) => returnVal.push(item));
    const filteredWithChannel = returnVal.filter(
      ({ startdate, enddate, channelId }) => channelId === optPick?.id && true
    );

    if (optPick !== undefined) {
      return filteredWithChannel;
    } else {
      return returnVal;
    }
  }, [groupScheduleData, optPick]);
  const { data: channelList } = useGetChannelsQuery({ active: true });

  function handleInputGroupScheduleItem<
    K extends keyof Partial<GroupScheduleObj>,
    V extends Partial<GroupScheduleObj>[K]
  >(key: K, index: number, value: V) {
    let list = [...groupScheduleInput];
    list[index] = { ...list[index], [key]: value };
    setGroupScheduleInput(list);
  }

  useEffect(() => {
    if (groupScheduleDataList && showEditModal) {
      if (!groupScheduleDataList) return;
      let arr: GroupScheduleObj[] = [...groupScheduleDataList];
      setGroupScheduleInput(
        arr.map((item) => {
          return {
            id: item.id,
            clientcode: item.clientcode,
            channelId: item.channelId,
            startdate:
              item.startdate &&
              `${format(new Date(item.startdate), "yyyy-MM-dd")}`,
            enddate:
              item.enddate && `${format(new Date(item.enddate), "yyyy-MM-dd")}`,
            active: item.active,
            delete: item.delete === undefined ? false : item.delete,
          };
        })
      );
    } else {
      setGroupScheduleInput([]);
    }
  }, [groupScheduleDataList, showEditModal]);

  useEffect(() => {
    if (!showModal) {
      setSelectedChannel(undefined);
      setStartDate("");
      setEndDate("");
    }
  }, [showModal]);

  const getChannelbyId = (id: number) => {
    if (!channelList) return;
    const pickedChannel = channelList.find((item) => item.id === id);
    return pickedChannel;
  };

  const handleCreateGroupSchedule = React.useCallback(() => {
    setIsLoading(true);
    if (startDate && endDate) {
      const obj: Omit<GroupScheduleReqObj, "channel"> = {
        channelId: selectedChannel?.value ?? 0,
        startdate: startDate.toString(),
        enddate: endDate.toString(),
        delete: false,
      };
      createGroupSchedule(obj)
        .unwrap()
        .then(() => {
          setIsLoading(false);
          setShowModal(false);
          toast("Grup Jadwal berhasil dibuat", {
            type: "success",
          });
        })
        .catch(() => {
          setIsLoading(false);
          toast("Gagal membuat grup jadwal karena tanggal tidak valid.", {
            type: "error",
          });
        });
    } else {
      setIsLoading(false);
      toast("Gagal membuat grup jadwal. Silahkan periksa form anda kembali.", {
        type: "error",
      });
    }
  }, [endDate, startDate, selectedChannel, createGroupSchedule]);

  const handleUpdateGroupSchedule = React.useCallback(() => {
    setIsLoading(true);
    const obj: UpdateGroupSchedule = {
      schedulegroups: groupScheduleInput.map((item) => {
        return {
          startdate:
            item.startdate &&
            `${format(new Date(item.startdate), "yyyy-MM-dd")}`,
          enddate:
            item.enddate && `${format(new Date(item.enddate), "yyyy-MM-dd")}`,
          ...item,
        };
      }),
    };
    updateGroupSchedule(obj)
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setShowEditModal(false);
        toast("Grup Jadwal berhasil diubah", {
          type: "success",
        });
      })
      .catch((e: ErrorMessageBackendDataShape) => {
        setIsLoading(false);
        toast("Gagal mengubah grup jadwal karena tanggal tidak valid.", {
          type: "error",
        });
      });
  }, [groupScheduleInput, updateGroupSchedule]);

  return (
    <PageContainer
      className={classes.container}
      headerNavigationContent={
        <>
          <div className={classes.headerSideContainer}>
            <button
              className={classes.backButton}
              onClick={() => navigate("/")}
            >
              <IoIosArrowRoundBack size={24} />
            </button>
          </div>
          <div className={classes.headerTitleContainer}>
            <p className={classes.textTitle}>Pilih Jadwal Channel</p>
          </div>
          <div
            className={classes.headerSideContainer}
            onClick={() => {
              setShowEditModal(!showEditModal);
            }}
          >
            <span
              style={{
                fontWeight: 700,
                color: COLORS.blue_1,
                fontSize: 13,
                cursor: "pointer",
              }}
            >
              UBAH
            </span>
          </div>
        </>
      }
      headerContent={
        <div className={classes.carouselContainer}>
          <ButtonBase
            className={[
              classes.chipsContainer,
              typeof optPick === "undefined" ? "pick" : undefined,
            ].join(" ")}
            onClick={() => {
              setOptPick(undefined);
            }}
          >
            {"Semua"}
          </ButtonBase>
          {channelOpts
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((channel) => {
              return (
                <ButtonBase
                  key={channel.id}
                  className={[
                    classes.chipsContainer,
                    optPick?.id === channel.id ? "pick" : undefined,
                  ].join(" ")}
                  onClick={() => setOptPick(channel)}
                >
                  {channel.description}
                </ButtonBase>
              );
            })}
        </div>
      }
      withBackground
    >
      <div
        className={classes.scheduleListContainer}
        style={{ minHeight: "100vh" }}
      >
        {groupScheduleDataList &&
          groupScheduleDataList.map((item) => (
            <ButtonBase
              className={classes.scheduleCardContainer}
              onClick={() =>
                navigate({
                  pathname: `schedulegroupId/${item.id}`,
                  search: `channelId=${item.channelId}`,
                })
              }
              key={item.id}
            >
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <button
                  className={tagClasses.tag}
                  style={{
                    backgroundColor: getChannelbyId(item.channelId)?.color,
                  }}
                >
                  {getChannelbyId(item.channelId)?.description}
                </button>
                <span style={{ fontWeight: 600, fontSize: 12 }}>
                  Tanggal :{" "}
                  <span>{`${format(
                    new Date(item.startdate),
                    "yyyy-MM-dd"
                  )} sd ${format(new Date(item.enddate), "yyyy-MM-dd")}`}</span>
                </span>
              </div>
              <button
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexBasis: "30px",
                  backgroundColor: "transparent",
                  border: 0,
                }}
              >
                <IoIosArrowForward size={12} />
              </button>
            </ButtonBase>
          ))}
        {groupScheduleDataList?.length === 0 && (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada jadwal yang ditemukan</span>
          </div>
        )}
      </div>
      <AddButton onClick={() => setShowModal(!showModal)} />
      <CustomModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className={modal_classes.modal}
      >
        <ModalBody>
          <div className={classes.modalHeaderContainer}>
            <div className={classes.modalSideHeader}></div>
            <div className={classes.modalHeaderTitle}>
              <p>Buat Grup Jadwal</p>
            </div>
            <div className={classes.modalSideHeader}>
              <button
                onClick={() => setShowModal(!showModal)}
                style={{ backgroundColor: "transparent", border: 0 }}
              >
                <BaseIcon
                  src={closeIcon_SVG}
                  title="closeIcon"
                  fontSize={0}
                  color={COLORS.black_1}
                />
              </button>
            </div>
          </div>
          <div>
            <Label
              style={{
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Channel
            </Label>
            <PureInputSelect
              defaultValue={selectedChannel}
              options={channelOpts}
              onChange={(val) => setSelectedChannel(val)}
            />
            <Label
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              Tanggal
            </Label>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <Input
                  defaultValue={startDate?.toString()}
                  onChange={(val) => setStartDate(val.target.value)}
                  type={"date"}
                  min={formatISO(startOfDay(new Date()), {
                    representation: "date",
                  })}
                />
              </div>
              <div
                style={{
                  flex: 0.1,
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>sd</span>
              </div>
              <div style={{ flex: 0.5 }}>
                <Input
                  defaultValue={endDate?.toString()}
                  onChange={(val) => setEndDate(val.target.value)}
                  type={"date"}
                  min={formatISO(startOfDay(new Date()), {
                    representation: "date",
                  })}
                ></Input>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <button
              style={{
                background: COLORS.gradientGray_1,
                width: "100px",
                borderRadius: "5px",
                height: "40px",
                border: 0,
                color: "#fff",
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: 600,
              }}
              onClick={() => setShowModal(false)}
            >
              BATAL
            </button>
            <button
              className={classes.saveScheduleButton}
              disabled={isLoading}
              onClick={handleCreateGroupSchedule}
            >
              {isLoading ? "LOADING" : "BUAT"}
            </button>
          </div>
        </ModalBody>
      </CustomModal>
      {groupScheduleInput && (
        <CustomModal
          isOpen={showEditModal}
          toggle={() => setShowEditModal(!showEditModal)}
          className={modal_classes.modal}
          style={{ padding: 0 }}
        >
          <ModalBody style={{ padding: 0 }}>
            <div className={classes.modalHeaderContainer}>
              <div className={classes.modalSideHeader}></div>
              <div className={classes.modalHeaderTitle}>
                <p style={{ fontWeight: 700, letterSpacing: 0.5 }}>
                  Ubah Grup Jadwal
                </p>
              </div>
              <div className={classes.modalSideHeader}>
                <button
                  onClick={() => setShowEditModal(!showEditModal)}
                  style={{ backgroundColor: "transparent", border: 0 }}
                >
                  <BaseIcon
                    src={closeIcon_SVG}
                    title="closeIcon"
                    fontSize={0}
                    color={COLORS.black_1}
                  />
                </button>
              </div>
            </div>
            {!groupScheduleInput ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size={18}
                  color="inherit"
                  style={{ color: COLORS.blue_1 }}
                />
              </div>
            ) : (
              groupScheduleInput.map((item, index) => (
                <Row key={item.id}>
                  <Col>
                    <Label
                      style={{
                        fontSize: "12px",
                        marginTop: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Channel
                    </Label>
                    <PureInputSelect
                      defaultValue={{
                        value: item?.channelId ?? 0,
                        label: `${
                          item.channelId &&
                          (getChannelbyId(item.channelId)?.description ?? "")
                        }`,
                      }}
                      options={channelOpts}
                      onChange={(val) =>
                        handleInputGroupScheduleItem(
                          "channelId",
                          index,
                          val?.value
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Label
                      style={{
                        fontFamily: "Raleway",
                        fontSize: "12px",
                        marginTop: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Tanggal
                    </Label>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        gap: 8,
                      }}
                    >
                      <div style={{ flex: 0.5 }}>
                        <Input
                          defaultValue={
                            item.startdate &&
                            formatISO(new Date(item.startdate), {
                              representation: "date",
                            }).toString()
                          }
                          onChange={(val) =>
                            handleInputGroupScheduleItem(
                              "startdate",
                              index,
                              val.target.value
                            )
                          }
                          min={formatISO(startOfDay(new Date()), {
                            representation: "date",
                          })}
                          style={{ height: 40 }}
                          type={"date"}
                        />
                      </div>
                      <div
                        style={{
                          flex: 0.1,
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>sd</span>
                      </div>
                      <div style={{ flex: 0.5 }}>
                        <Input
                          defaultValue={
                            item.enddate &&
                            formatISO(new Date(item.enddate), {
                              representation: "date",
                            }).toString()
                          }
                          onChange={(val) =>
                            handleInputGroupScheduleItem(
                              "enddate",
                              index,
                              val.target.value
                            )
                          }
                          min={formatISO(startOfDay(new Date()), {
                            representation: "date",
                          })}
                          style={{ height: 40 }}
                          type={"date"}
                        />
                      </div>
                      <BiTrash
                        color={
                          item.delete === true ? COLORS.gray : COLORS.red_1
                        }
                        fontSize={16}
                        cursor={"pointer"}
                        onClick={() =>
                          handleInputGroupScheduleItem(
                            "delete",
                            index,
                            !item.delete
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ))
            )}
            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <button
                style={{
                  background: COLORS.gradientGray_1,
                  width: "100px",
                  borderRadius: "5px",
                  height: "40px",
                  border: 0,
                  color: "#fff",
                  fontFamily: "Raleway",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
                onClick={() => setShowEditModal(false)}
              >
                BATAL
              </button>
              <button
                className={classes.saveScheduleButton}
                disabled={isLoading}
                onClick={handleUpdateGroupSchedule}
              >
                {isLoading ? "LOADING" : "SIMPAN"}
              </button>
            </div>
          </ModalBody>
        </CustomModal>
      )}
    </PageContainer>
  );
};

export default AdminScheduleChannelListPage;
