import { useGetMaterialsQuery } from "../../redux/MaterialStore/materialstoreAPI";
import { Nullable } from "../../types";
import { AMaterial, AMaterialOpt, MaterialReqObj } from "../../types/material";

// type Props = AMaterial

type Props = {
  args?: object;
  opt?: { skip?: boolean };
};

const useMaterialOptions = (
  props: Props
): {
  arr?: AMaterialOpt[];
  isLoading?: boolean;
} => {
  const { args, opt } = props;
  const { data, isLoading, isFetching } = useGetMaterialsQuery(args || {}, {
    skip: opt?.skip,
  });
  return {
    arr: data?.map((material) => {
      return {
        ...material,
        label: material.description,
        value: material.id,
      };
    }),
    isLoading: isLoading || isFetching,
  };
};

export default useMaterialOptions;
