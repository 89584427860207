import React, { ChangeEvent, useCallback, useState } from "react";
import { BiHistory, BiTask } from "react-icons/bi";
import {
  add,
  endOfDay,
  format,
  isFuture,
  parse,
  startOfDay,
  sub,
} from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsCalendar2Range, BsThreeDotsVertical } from "react-icons/bs";
import { COLORS } from "../assets/theme";
import PageContainer from "../components/PageContainer/PageContainer";
import Ticket from "../components/atoms/Ticket";
import Header from "../components/atoms/Header";
import useUsertype from "../hooks/auth/useUsertype";
import { ROUTES } from "../config/nav";
import enLocale from "date-fns/locale/en-US";
import LoadingItem from "../components/atoms/LoadingContainer/LoadingItem";
import useChannelOptions from "../hooks/channels/useChannelOptions";
import { useGetReservationListQuery } from "../redux/ReservationStore/reservationstoreAPI";
import useListContainerStyles from "../hooks/styles/useListContainerStyles";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { IoIosClose, IoMdCloseCircle, IoMdSearch } from "react-icons/io";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import {
  MobileDateRangePicker,
  LocalizationProvider,
  DateRange,
} from "@material-ui/pickers";
import {
  ButtonBase,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import { dateIsInRange } from "../tools";
import { ReservationRequestObj } from "../types/reservation";
import { BackendPaginationRequestObject } from "../types";
import useLastElementInfiniteScroll from "../hooks/useLastElementInfiniteScroll";

interface Props {}

const datePickertheme = createTheme({
  typography: {
    fontFamily: "Raleway",
    fontSize: 18,
  },
});

const ListReservationPage: React.FunctionComponent<Props> = () => {
  const [params, setParams] = useSearchParams();

  const currentDate = new Date();
  const tommorowDate = add(currentDate, { days: 1 });
  // const sevenDaysAgo = add(currentDate, {
  //   days: -7,
  // });
  const sixDaysAgo = add(currentDate, {
    days: -6,
  });
  const optId = React.useMemo(() => {
    const id = params.get("code");
    return id ? parseInt(id) : undefined;
  }, [params]);
  const isHistory: boolean = params.get("history") === "true";
  const [selectedDateRangeDate, setSelectedDateRangeDate] = React.useState<
    DateRange<Date>
  >([currentDate, tommorowDate]);
  const handleDateRangeChange = (date: DateRange<Date>) => {
    const [startDate, endDate] = date;
    params.set(
      "startDate",
      format(startDate !== null ? startDate : new Date(), "yyyy-MM-dd")
    );
    params.set(
      "endDate",
      format(endDate !== null ? endDate : new Date(), "yyyy-MM-dd")
    );
    setParams(params);
    setSelectedDateRangeDate(date);
  };
  const startDate: string | null = params.get("startDate");
  const endDate: string | null = params.get("endDate");

  const navigate = useNavigate();
  const classes = useListContainerStyles();
  const [showSearchText, setShowSearchText] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const usertype = useUsertype();
  const { arr } = useChannelOptions(undefined, {
    skip: usertype !== "officer",
  });
  const {
    data,
    isLoading: isLoadingReserveList,
    isFetching: isFetchingReserveList,
  } = useGetReservationListQuery(
    startDate && endDate
      ? isHistory
        ? {
            aftertime: format(new Date(startDate), "yyyy-MM-dd hh:mm aaaaa'm"),
            beforetime: format(new Date(endDate), "yyyy-MM-dd hh:mm aaaaa'm"),
            page: 1,
            take: limit,
            channelId: optId,
            isHistory: true,
            nopol: searchInput ? searchInput : undefined,
          }
        : {
            aftertime: format(new Date(startDate), "yyyy-MM-dd hh:mm aaaaa'm"),
            beforetime: format(new Date(endDate), "yyyy-MM-dd hh:mm aaaaa'm"),
            page: 1,
            take: limit,
            isList: true,
            channelId: optId,
            nopol: searchInput ? searchInput : undefined,
          }
      : isHistory
      ? {
          page: 1,
          take: limit,
          channelId: optId,
          isHistory: true,
          nopol: searchInput ? searchInput : undefined,
        }
      : {
          page: 1,
          take: limit,
          channelId: optId,
          nopol: searchInput ? searchInput : undefined,
          isList: true,
        }
  );
  const lastElementRef = useLastElementInfiniteScroll({
    isFetching: isFetchingReserveList,
    page: 1,
    meta: data?.meta,
    setLimit,
    limit,
  });
  const setOpenDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const reserveList = React.useMemo(() => {
    if (!data || !data.entities) return [];
    let dataList = [...data.entities];
    return dataList;
  }, [data]);

  const isLoading = isLoadingReserveList || isFetchingReserveList;

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchInput(e.target.value);
      setLimit(10);
    }, 1000);
  };

  const handleOnClickSetHistoryDropdown = useCallback(() => {
    {
      let current = params.get("history") === "true";
      params.set("history", "" + !current);
      setParams(params);
    }
  }, [isHistory]);

  return (
    <PageContainer
      headerNavigationContent={
        <>
          {!showSearchText ? (
            <>
              <Header
                backButtonOnClick={() => {
                  switch (usertype) {
                    case "officer":
                      navigate(`${ROUTES.officer}`);
                      break;
                    case "user":
                      navigate(ROUTES.user);
                  }
                }}
                showBackButton={true}
                title={`${isHistory ? "Riwayat" : "List"} Reservasi`}
              />
              <BsCalendar2Range
                cursor={"pointer"}
                color={COLORS.black_1}
                size={16}
                onClick={() => setDatePickerOpen(!datePickerOpen)}
              />
              <LocalizationProvider
                dateAdapter={DateFnsAdapter}
                locale={enLocale}
              >
                <ThemeProvider theme={datePickertheme}>
                  <MobileDateRangePicker
                    value={selectedDateRangeDate}
                    onChange={handleDateRangeChange}
                    open={datePickerOpen}
                    showToolbar={false}
                    disablePast={!isHistory}
                    renderInput={(props) => <></>}
                    onClose={() => {
                      setDatePickerOpen(!datePickerOpen);
                    }}
                  />
                </ThemeProvider>
              </LocalizationProvider>
              <Dropdown
                style={{ marginLeft: 8 }}
                isOpen={dropdownIsOpen}
                toggle={setOpenDropdown}
              >
                <DropdownToggle
                  tag="span"
                  onClick={setOpenDropdown}
                  data-toggle="dropdown"
                  aria-expanded={dropdownIsOpen}
                >
                  <BsThreeDotsVertical
                    size={16}
                    cursor="pointer"
                    color={COLORS.black_1}
                  />
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: 6 }} end>
                  <DropdownItem
                    style={{
                      padding: 8,
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                    onClick={() => setShowSearchText(!showSearchText)}
                  >
                    <IoMdSearch size={16} color={COLORS.black_1} />
                    <span>Cari Reservasi</span>
                  </DropdownItem>
                  <DropdownItem
                    style={{
                      padding: 8,
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                    onClick={() => handleOnClickSetHistoryDropdown()}
                  >
                    {!isHistory ? (
                      <>
                        <BiHistory size={16} color={COLORS.black_1} />
                        <span>Riwayat Reservasi</span>
                      </>
                    ) : (
                      <>
                        <BiTask size={16} color={COLORS.black_1} />
                        <span>List Reservasi</span>
                      </>
                    )}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <div className={classes.searchWrapper} style={{ width: "100%" }}>
              <Input
                id="search-reservation"
                name="search-reservation"
                placeholder="Cari tiket berdasarkan nomor kendaraan..."
                type="text"
                onChange={handleSearch}
                style={{
                  height: "40px",
                  width: "100%",
                  border: "none",
                  borderBottom: `1px solid ${COLORS.gray_1}`,
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              />
              <IoIosClose
                size={24}
                cursor="pointer"
                color={COLORS.black_1}
                onClick={() => {
                  setSearchInput("");
                  setShowSearchText(!showSearchText);
                }}
              />
            </div>
          )}
        </>
      }
      headerContent={
        <div className={classes.headerContainer}>
          {usertype === "officer" && (
            <div className={classes.tagContainer}>
              <ButtonBase
                className={[classes.tag, !optId ? "active" : undefined].join(
                  " "
                )}
                onClick={() => {
                  params.delete("code");
                  setParams(params);
                }}
              >
                Semua
              </ButtonBase>
              {arr
                ?.filter((item) => item.active === true)
                .sort((a, b) => a.description.localeCompare(b.description))
                .map((val) => {
                  return (
                    <ButtonBase
                      key={val.id}
                      className={[
                        classes.tag,
                        val.value === optId ? "active" : undefined,
                      ].join(" ")}
                      onClick={() => {
                        params.set("code", "" + val.id);
                        setParams(params);
                      }}
                    >
                      {val.label}
                    </ButtonBase>
                  );
                })}
            </div>
          )}
        </div>
      }
      className={classes.container}
      withBackground
    >
      <div className={classes.listContainer} style={{ minHeight: "100vh" }}>
        {startDate && endDate && (
          <button
            className={classes.filteredButton}
            onClick={() => {
              params.delete("startDate");
              params.delete("endDate");
              setParams(params);
            }}
          >
            Filtered by Date <IoMdCloseCircle size={16} />
          </button>
        )}
        {/* <LoadingItem isLoading={isLoading} /> */}
        <>
          {reserveList?.map((ticket) => {
            const { color } =
              arr.find((val) => {
                return val.id === ticket.channelId;
              }) ?? {};
            let tags = [
              {
                desc: ticket.channel.description,
                color: color ?? COLORS.green,
              },
            ];
            ticket.nopol &&
              tags.push({
                desc: ticket.nopol,
                color: COLORS.black_1,
              });
            !ticket.active &&
              tags.push({
                desc: "Dibatalkan",
                color: COLORS.red_1,
              });
            ticket.createdByUser.usertype.name === "user" &&
              usertype === "officer" &&
              tags.push({
                desc: "Dibuat oleh User",
                color: COLORS.brown_1_rgb,
              });
            ticket.statusTruck !== "reserved" &&
              ticket.statusTruck !== "checkedout" &&
              tags.push({
                desc: "Dalam Proses",
                color: COLORS.blue_5_rgb,
              });
            return (
              <div ref={lastElementRef} key={ticket.id}>
                <Ticket
                  ticketLabel={"" + ticket.qrid}
                  tags={tags}
                  ticketDate={ticket.date}
                  ticketStartTime={ticket.schedule.from}
                  ticketEndTime={ticket.schedule.to}
                  isHistory={isHistory}
                  onClickTicket={() => {
                    navigate(`${ROUTES.ticket}/${ticket.id}`);
                  }}
                />
              </div>
            );
          })}
        </>
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={18}
              color="inherit"
              style={{ color: COLORS.blue_1 }}
            />
          </div>
        )}
        {reserveList?.length === 0 ? (
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>Tidak ada reservasi yang ditemukan</span>
          </div>
        ) : (
          isHistory &&
          (!startDate || !endDate) && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <span style={{ fontStyle: "italic", color: COLORS.gray }}>
                Riwayat Reservasi yang ditampilkan adalah 7 hari terakhir.
              </span>
            </div>
          )
        )}
      </div>
    </PageContainer>
  );
};

export default ListReservationPage;
