import axios from "axios";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import { BackendDataPageShape, BackendDataShape, USERLINKS } from "../../types";
import { GetUserDataReqObj, UserDataWOToken } from "../../types/user";

export {};

// export
export function getUser(obj: GetUserDataReqObj) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${USERLINKS.user(params)}`;
  const cookie = new CookieMonster();
  return axios.get<BackendDataPageShape<UserDataWOToken[]>>(url, {
    headers: {
      Authorization: `Bearer ${cookie.loadCookie("jwt")}`,
    },
  });
}
