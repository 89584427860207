import React from "react";
import { createUseStyles } from "react-jss";
import { IoIosAdd } from "react-icons/io";
import { COLORS } from "../../assets/theme";
import { ButtonBase, IconButton, makeStyles } from "@material-ui/core";

interface Props {
  onClick?: () => void;
}

const useStyles = makeStyles(() => {
  return {
    addButtonContainer: {
      height: 50,
      width: 50,
      borderRadius: 25,
      background: COLORS.gradientBlue_3,
      color: "#fff",
      border: 0,
      position: "fixed",
      right: 30,
      bottom: 30,
      zIndex: 99,
    },
  };
});
const AddButton: React.FunctionComponent<Props> = ({
  onClick: buttonClick,
}) => {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.addButtonContainer} onClick={buttonClick}>
      <IoIosAdd size={24} />
    </ButtonBase>
  );
};

export default AddButton;
