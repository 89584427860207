import React from "react";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import { createUseStyles } from "react-jss";
import { RequireSome } from "../../types";
import { COLORS } from "../theme";
import {
  Current_SVG,
  ReservationList_SVG,
  Reservation_SVG,
  WalkInReservation_SVG,
  closeIcon_SVG,
  verifiedIcon_SVG,
  logo_svg,
} from "./svg";

export type MinSVGProps = RequireSome<SVGProps, "title" | "src">;

export const BasicIconProps: MinSVGProps = {
  title: "basic_icon",
  src: Current_SVG,
  fill: COLORS.black_1,
  stroke: COLORS.black_1,
  height: 16,
  width: 16,
  viewBox: "0 0 48 48",
  fontSize: "1rem",
};

const useIconDefaultStyles = createUseStyles<"icon", Partial<SVGProps>>({
  icon: {
    "> path": ({ fill }) => {
      return {
        fill,
        stroke: fill,
      };
    },
  },
});

export const BaseIcon: React.FunctionComponent<Partial<SVGProps>> = ({
  className,
  ...props
}) => {
  const classes = useIconDefaultStyles(props);
  return (
    <SVG
      className={[classes.icon, className].join(" ")}
      preProcessor={(code) =>
        code.replace(/fill=".*?"/g, 'fill="currentColor"')
      }
      {...BasicIconProps}
      {...props}
    />
  );
};

export const CurrentSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  ...rest
}) => (
  <BaseIcon
    title={title ?? "current_icon"}
    src={src ?? Current_SVG}
    {...rest}
  />
);

// width="156" height="64"

export const FajarPaperSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  width,
  height,
  fill,
  stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? "Dayasa Paper Logo"}
    src={logo_svg}
    width={width ?? 156}
    height={height ?? 64}
    fill={"none"}
    stroke={"none"}
    // hotfix to adjust preprocessor
    preProcessor={(code) => code}
    {...rest}
  />
);
export const VerifiedIconSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  width,
  height,
  fill,
  stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? "VerifiedIcon"}
    src={verifiedIcon_SVG}
    width={width ?? 66}
    height={height ?? 66}
    fill={"none"}
    stroke={"none"}
    // hotfix to adjust preprocessor
    preProcessor={(code) => code}
    {...rest}
  />
);

export const ReservationSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  width,
  height,
  // fill,
  // stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? ""}
    src={Reservation_SVG}
    width={width ?? 24}
    height={height ?? 26}
    // fill={"none"}
    // stroke={"none"}
    {...rest}
  />
);

export const WalkInReservationSVG: React.FunctionComponent<
  Partial<SVGProps>
> = ({
  title,
  src,
  width,
  height,
  // fill,
  // stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? ""}
    src={WalkInReservation_SVG}
    width={width ?? 24}
    height={height ?? 26}
    // fill={"none"}
    // stroke={"none"}
    {...rest}
  />
);

export const ReservationListSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  width,
  height,
  // fill,
  // stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? ""}
    src={ReservationList_SVG}
    width={width ?? 24}
    height={height ?? 26}
    // fill={"none"}
    // stroke={"none"}
    {...rest}
  />
);
export const CloseIconSVG: React.FunctionComponent<Partial<SVGProps>> = ({
  title,
  src,
  width,
  height,
  // fill,
  // stroke,
  ...rest
}) => (
  <BaseIcon
    title={title ?? ""}
    src={closeIcon_SVG}
    width={width ?? 24}
    height={height ?? 26}
    // fill={"none"}
    // stroke={"none"}
    {...rest}
  />
);
