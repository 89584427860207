import React from "react";
import * as yup from "yup";

const useSchemaCreateAccount = () => {
  return React.useMemo(() => {
    let username = yup.string();
    let password = yup.string();
    let email = yup.string().email("Email wajib berupa email!");
    let usertypeId = yup.number();
    let schema = yup.object().shape({
      username: username
        .min(1, "Username wajib diisi!")
        .required("Username wajib diisi!"),
      password: password.min(6, "Error! Password minimal 6 karakter."),
      email: email.required("Email wajib diisi!"),
      usertypeId: usertypeId
        .required("Tipe akun wajib dipilih!")
        .transform((value) => (isNaN(value) ? undefined : value)),
    });
    return {
      schema,
    };
  }, []);
};

export default useSchemaCreateAccount;
