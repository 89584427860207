import { createUseStyles } from "react-jss";
import { COLORS } from "../../../assets/theme";

export const useAdminAccountPageStyle = createUseStyles(() => {
  return {
    container: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    modalHeaderContainer: {
      flexDirection: "row",
      justifyContent: "center",
      display: "flex",
      paddingTop: "10px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    modalSideHeader: {
      flexBasis: "20px",
      display: "flex",
    },
    modalHeaderTitle: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
      fontFamily: "Raleway",
      fontWeight: 600,
    },
    headerContainer: {
      flexDirection: "row",
      flexBasis: "40px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2.5rem 0",
    },
    headerSideContainer: {},
    headerTitleContainer: {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      flex: 1,
    },
    searchWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    backButton: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      border: 0,
      backgroundColor: "transparent",
    },
    textTitle: {
      color: COLORS.black_1,
      fontFamily: "Raleway",
      fontWeight: 700,
      letterSpacing: 0.5,
      fontSize: 16,
      margin: 0,
    },
    mandatoryStar: {
      color: "red",
      fontSize: 24,
      verticalAlign: "middle",
      height: 0,
      position: "absolute",
      top: -8,
      margin: 0,
    },
    editButton: {
      backgroundColor: "transparent",
      border: 0,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      color: COLORS.blue_1,
      fontFamily: "Raleway",
      fontSize: "13px",
      fontWeight: 600,
    },
    carouselContainer: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      width: "100%",
      overflowY: "hidden",
      marginBottom: "1.6rem",
      alignItems: "center",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
    },
    chipsContainer: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "25px",
      width: "fit-content",
      marginRight: "1rem",
      borderRadius: "15px",
      fontFamily: "Raleway",
      fontSize: "10px",
      backgroundColor: "transparent",
      border: 1,
      borderStyle: "solid",
      borderColor: COLORS.gray,
      color: "black",
      whiteSpace: "nowrap",
      "&.pick": {
        backgroundColor: COLORS.blue_1,
        border: 0,
        color: "white",
      },
    },
    textFormTitle: {
      fontFamily: "Raleway",
      fontSize: 12,
      fontWeight: 600,
      marginTop: "10px",
      position: "relative",
    },
    cardContainer: {
      display: "flex",
      backgroundColor: "white",
      borderColor: COLORS.gray_1,
      borderRadius: "8px",
      marginBottom: "8px",
      marginLeft: "16px",
      marginRight: "16px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    cardBodyContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "20px",
      paddingLeft: "20px",
      height: "100px",
    },
    addApproverButton: {
      margin: "16px 0",
      color: COLORS.blue_1,
      borderRadius: 4,
      border: `1.2px solid ${COLORS.blue_1}`,
      padding: 8,
      background: "transparent",
      width: "100%",
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
  };
});
