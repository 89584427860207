import React from "react";
import { TruckPath_SVG } from "../../assets/Icons/svg";
import SVG from "react-inlinesvg";

type Props = {
  className?: string;
};

const Truck: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <SVG title="Dayasa Paper Truck" className={className} src={TruckPath_SVG} />
  );
};

export default Truck;
