import { BasicSelectOpt, Nullable } from "..";
import { COLORS } from "../../assets/theme";
import { RelationApproverChannelObj } from "../user";

export enum ChannelType {
  Wastepaper = 1,
  "Finished Goods" = 2,
  "Chemical" = 3,
  "Fuel" = 4,
  "Import" = 5,
}

export interface ChannelOpt extends BasicSelectOpt<number>, AChannelWithColor {}

export interface ChannelReqObj {
  id?: number;
  clientCode?: number;
  channel?: string;
  description?: string;
  active?: boolean;
}

export type ChannelCreateObj = Omit<AChannel, "id" | "clientcode">;

// export interface ChannelCreateObj {
//   channel: string;
//   description: string;
//   active: boolean;
//   statusTruck?: AChannelValidation;
//   statusTruckBlacklist?: boolean;
//   statusDriverName?: AChannelValidation;
//   statusVendor?: AChannelValidation;
//   statusMaterial?: AChannelValidation;
//   statusPo?: AChannelValidation;
//   statusDeliveryNote?: AChannelValidation;
//   statusContainer?: AChannelValidation;
//   statusPhoto?: AChannelValidation;
//   approvalCount?: number;
// }

export interface ChannelIdentifier {
  id: number;
}

export interface ChannelGeneratedMaps {
  id: number;
}

export interface ChannelRaw {
  id: number;
}

export interface ChannelCreateRes {
  identifiers: ChannelIdentifier[];
  generatedMaps: ChannelGeneratedMaps[];
  raw: ChannelRaw[];
}

export type ChannelUpdateObj = Omit<AChannel, "clientcode">;

// export interface ChannelUpdateObj {
//   channel: string;
//   description: string;
//   active: boolean;
//   statusTruck?: AChannelValidation;
//   statusTruckBlacklist?: boolean;
//   statusDriverName?: AChannelValidation;
//   statusVendor?: AChannelValidation;
//   statusMaterial?: AChannelValidation;
//   statusPo?: AChannelValidation;
//   statusDeliveryNote?: AChannelValidation;
//   statusContainer?: AChannelValidation;
//   statusPhoto?: AChannelValidation;
//   approvalCount?: number;
// }

export type ChannelUpdateRes = string;

export type AChannelValidation = {
  /**
   * if `true` this status should be shown in form
   */
  active: boolean;
  /**
   * if `true`, this status should be optional in form
   */
  optional: boolean;
};

export interface AChannel {
  id: number;
  clientcode: number;
  /**
   * kode channel
   */
  channel: string;
  description: string;
  active: boolean;
  statusTruck: AChannelValidation;
  statusTruckBlacklist: boolean;
  statusDriverName: AChannelValidation;
  /**
   * @todo sync this field name according to BE
   */
  statusDriverPhoneNumber?: Nullable<AChannelValidation>;
  statusVendor: AChannelValidation;
  statusMaterial: AChannelValidation;
  statusPo: AChannelValidation;
  statusDeliveryNote: AChannelValidation;
  statusContainer: AChannelValidation;
  statusShipmentNumber: AChannelValidation;
  statusPhoto: AChannelValidation;
  statusTruckValidation?: boolean;
  statusPoValidation?: boolean;
  /**
   * jumlah approval yg dibutuhkan untuk walk-in
   */
  approvalCount: number;
}

export interface AChannelWithColor extends AChannel {
  color: string;
}

export interface AChannelWithColorWithRelationApproverChannel
  extends RelationApproverChannelObj {
  color: string;
}

export type AChannelShort = Pick<
  AChannel,
  "id" | "channel" | "description" | "active"
>;

export const sampleChannel: AChannel = {
  id: 1,
  clientcode: 300,
  channel: "KLD",
  description: "Recycle Paper General",
  active: true,

  statusTruckBlacklist: true,
  statusTruckValidation: true,
  statusTruck: {
    active: true,
    optional: false,
  },
  statusDriverName: {
    active: true,
    optional: false,
  },
  statusVendor: {
    active: true,
    optional: false,
  },
  statusMaterial: {
    active: true,
    optional: false,
  },
  statusPo: {
    active: false,
    optional: false,
  },
  statusDeliveryNote: {
    active: false,
    optional: false,
  },
  statusContainer: {
    active: false,
    optional: false,
  },
  statusShipmentNumber: {
    active: false,
    optional: false,
  },
  statusPhoto: {
    active: false,
    optional: false,
  },
  approvalCount: 1,
};

export const DUMMY_CHANNELS = [
  {
    id: 1,
    name: "Waste Paper",
    color: COLORS.green,
  },
  {
    id: 2,
    name: "Chemical",
    color: COLORS.purple,
  },
  {
    id: 3,
    name: "Raw Materials",
    color: COLORS.orange_1,
  },
] as const;
