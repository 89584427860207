import axios, { AxiosRequestConfig } from "axios";
import { BackendDataShape, CREDLINKS } from "../../types";

export const sendUsernameResetPassword = (username: string) => {
  const url = CREDLINKS.sendUsernameForgotPassword;
  return axios.post<BackendDataShape<unknown>>(url, { username: username }, {});
};

export const changePassword = (password: string, token: string) => {
  const url = CREDLINKS.changePassword;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post<BackendDataShape<unknown>>(
    url,
    { password: password },
    config
  );
};
export const checkJwtValidationChangePassword = (token: string) => {
  const url = CREDLINKS.checkJwtValidation;
  let config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post<BackendDataShape<{ valid: boolean; used: boolean }>>(
    url,
    { jwt: token },
    config
  );
};
