import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { convertToEncodedURL } from "../../tools";
import CookieMonster from "../../tools/CookieMonster";
import {
  APaginationEntity,
  BackendDataPageShape,
  BackendDataShape,
  BackendPaginationRequestObject,
} from "../../types";
import {
  AReservationResultObj,
  AWEReservationParams,
  AWIReservation,
  AWIReservationRequestObj,
  ReservationRequestObj,
} from "../../types/reservation";
import { BASIC_URL } from "../basicurl";

export const walkinReservationStoreAPI = createApi({
  reducerPath: "WIreservationstoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASIC_URL}/reservationwalkin`,
    prepareHeaders: (headers) => {
      const cookie = new CookieMonster();
      let token = cookie.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["WalkinReservationList"],
  endpoints: (builder) => {
    return {
      getWIReservationList: builder.query<
        APaginationEntity<AWIReservation[]>,
        BackendPaginationRequestObject<AWEReservationParams>
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: "/find" + params,
            method: "GET",
          };
        },
        providesTags: () => ["WalkinReservationList"],
        transformResponse: (res: BackendDataPageShape<AWIReservation[]>) => {
          return res.data;
        },
      }),
      createAWIReservation: builder.mutation<
        BackendDataShape<AReservationResultObj>,
        ReservationRequestObj
      >({
        query: (obj) => {
          return {
            url: "/create",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["WalkinReservationList"],
      }),
      updateAWIReservation: builder.mutation<
        BackendDataShape<AReservationResultObj>,
        Partial<AWIReservationRequestObj>
      >({
        query: ({ id, ...rest }) => {
          return {
            url: `/update/${id}`,
            method: "PATCH",
            body: rest,
          };
        },
        invalidatesTags: ["WalkinReservationList"],
      }),
      approveReservationMutation: builder.mutation<
        AReservationResultObj,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/approve/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WalkinReservationList"],
      }),
      rejectReservationMutation: builder.mutation<
        AReservationResultObj,
        {
          id: number;
        }
      >({
        query: ({ id }) => {
          return {
            url: `/reject/${id}`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["WalkinReservationList"],
      }),
      addPhotoWalkinReservation: builder.mutation<
        unknown,
        {
          id: number;
          images: FormData;
        }
      >({
        query: ({ id, images }) => {
          return {
            url: `/addphoto/${id}`,
            method: "PATCH",
            body: images,
          };
        },
        invalidatesTags: ["WalkinReservationList"],
      }),
    };
  },
});

export const {
  useGetWIReservationListQuery,
  useCreateAWIReservationMutation,
  useUpdateAWIReservationMutation,
  useApproveReservationMutationMutation,
  useRejectReservationMutationMutation,
  useAddPhotoWalkinReservationMutation,
  util: { resetApiState: resetWIReservationstoreAPI },
} = walkinReservationStoreAPI;
