import { Cookies } from "react-cookie";
import { CookieGetOptions, CookieSetOptions } from "universal-cookie";

export {};

export type UserCredCookies = "jwt" | "idUser" | "usertypeName" | "username";
export type UserDetailCookies = "channelId";

// extend as needed
export type KnownCookieNames = UserCredCookies | UserDetailCookies;
type CookieDataType = string | number | object;

export default class CookieMonster {
  private cookie: Cookies;
  private defaultSetConfig: CookieSetOptions = {
    path: "/",
    // secure: true,
    sameSite: true,
  };
  constructor() {
    this.cookie = new Cookies();
  }

  loadCookie(name: KnownCookieNames, opt?: CookieGetOptions) {
    return this.cookie.get(name, opt);
  }

  setCookie(
    name: KnownCookieNames,
    value: CookieDataType,
    opt?: CookieSetOptions
  ) {
    return this.cookie.set(name, value, {
      ...this.defaultSetConfig,
      ...opt,
    });
  }

  saveAllThisCookies(
    cookieDatas: {
      [key in KnownCookieNames]?: CookieDataType;
    },
    opt?: CookieSetOptions
  ) {
    for (let key in cookieDatas) {
      let k = key as KnownCookieNames;
      let data = cookieDatas[k];
      if (data) {
        this.cookie.set(k, data, {
          ...this.defaultSetConfig,
          ...opt,
        });
        // cookie.save(key, data, { path: "/", sameSite: true, ...options });
      }
    }
  }

  removeAllthisCookies(
    cookieNames: KnownCookieNames[],
    opt?: CookieSetOptions
  ) {
    cookieNames.forEach((name) => {
      this.cookie.remove(name, {
        ...this.defaultSetConfig,
        ...opt,
      });
    });
  }
}
